import React, { ReactElement, useEffect, useState } from 'react';
// import styles from './delivery-baggage.module.scss';
import { IntDeliveryBaggageProps } from '../../../schemas/baggage.interface';
import { useHistory } from 'react-router-dom';
import { setView } from '../../../core/masterdata/masterdata.reducer';
import { getCompany,setListCodePostal } from '../../../core/local-storage/local-storage.service';
import { connect, useDispatch } from 'react-redux';
import { IntStore } from '../../../schemas/store.interface';
import EaInput from '../../../components/form/ea-input/ea-input';
import { useForm } from '../../../hooks/use-forms';
import { initialFormDelivery, validationsDelivery } from './delivery-const'
import { useTranslation } from 'react-i18next';
import EaSelect from '../../../components/form/ea-select/ea-select';
import { showLoading } from '../../../components/ui/loading/loading.reducer';
import { getListCountries } from  '../baggage.service';
import { setFormDelivery } from '../baggabe.reducer';
import { getPhones, postDeliveries,getCodePostal } from './delivery-baggage.service';
import { getCodeCompany } from '../../user/user.service';


const DeliveryBaggage = (props: IntDeliveryBaggageProps): ReactElement => {


  const initialForm =initialFormDelivery;
  const history = useHistory();
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const companyStorage = getCompany();
  const [listCountries, setListCountries] = useState([]);  

  useEffect(() => {
      async function load() {
      props.showLoading();
      const countries = await props.getListCountries({...history});
      const mappingCountriesToSelect =  countries &&
                                        countries.length &&
                                        countries.map((country:any) => {
                    country.value = country.code;
                    return country;
      })

      setListCountries(mappingCountriesToSelect);
      initialForm.pais = props.baggage.countrySelected;
      const listcodes = await props.getCodePostal(history);      
      setListCodePostal(JSON.stringify(listcodes.map(({cp}) => (cp))));
    }
    load();
  }, []);

  /******************Control del formulario***********/
  const {
    values,
    errors,
    bindingFieldNameToUpperCase,
    bindingFieldName,
    bindingFielRadio,
    bindingFielCheck,
    isValidForm,
  } = useForm(validationsDelivery, initialForm);


  const {
    domicilio,
    numero,
    piso,
    localidad,
    provincia,
    codigoPostal,
    pais,
    dni,
    telefono,
    email,
    preferencia,
    observaciones,
    condiciones
  } = values;


  const eventSendDireccion = async (e) =>{
    e.preventDefault();

    if(!isValidForm()){
      return;
    }

    dispatch(props.showLoading());
    const phones = await props.getPhones(props.user.recordNumber ,history);

    dispatch(props.showLoading());
    const extraTelefonos = telefono + ',' + phones.data.code;

    const payload = {
      lossAgentParentId: props.user.lossAgentParentId,
      recordNumber: props.user.recordNumber,
      language:props.user.language,
      name: props.user.userForm.apellido,//apellido
      homeAddress:domicilio + ', ' + numero + ' - ' + piso,
      city:localidad,
      province:provincia,
      deliveryCountry:pais,
      postalCode:codigoPostal,
      document:dni,
      phone:extraTelefonos,
      email:email,
      preferency:preferencia,
      observations:observaciones,
      firm:getCodeCompany(companyStorage),
    }


    await props.postDeliveries(payload , history);
    history.push('/' + getCompany() + '/baggage/confirm');
    dispatch(setFormDelivery({...values}));
  }

  const goBack = () => {
    history.goBack()
  }

  return (
    <div data-testid="deliverybaggage">

      <div className="card">
        <form onSubmit={(e) => { eventSendDireccion(e) } } >
          <p>{t(`${companyStorage}.baggage_delivery.title`)}</p>
          <div className="ea-input-address-box">
            <EaInput
              label={t(`${companyStorage}.baggage_delivery.domicilioLb`)}
              title={t(`${companyStorage}.baggage_delivery.domicilioT`)}
              idInput={"domicilio-input"}
              type="text"
              bindingField={bindingFieldNameToUpperCase('domicilio')}
              error={errors}
              inputDataQa="domicilio-input"
              name="domicilio"
            ></EaInput>
            <EaInput
              idInput={"numero-input"}
              type="text"
              label={t(`${companyStorage}.baggage_delivery.domicilionumero`)}
              bindingField={bindingFieldNameToUpperCase('numero')}
              error={errors}
              inputDataQa="numero-input"
              name="numero"
            ></EaInput>
            <EaInput
              idInput={"piso-input"}
              type="text"
              label={t(`${companyStorage}.baggage_delivery.domiciliopiso`)}
              bindingField={bindingFieldNameToUpperCase('piso')}
              error={errors}
              inputDataQa="piso-input"
              name="piso"
            ></EaInput>
          </div>
        <div className="ea-input-box-two">
          <EaInput
            label={t(`${companyStorage}.baggage_delivery.localidadLb`)}
            title={t(`${companyStorage}.baggage_delivery.localidadT`)}
            idInput={"localidad-input"}
            type="text"
            bindingField={bindingFieldNameToUpperCase('localidad')}
            error={errors}
            inputDataQa="localidad-input"
            name="localidad"     
            autofill="true"       
          ></EaInput>

          <EaInput
            label={t(`${companyStorage}.baggage_delivery.provinciaLb`)}
            title={t(`${companyStorage}.baggage_delivery.provinciaT`)}
            idInput={"provincia-input"}
            type="text"
            bindingField={bindingFieldNameToUpperCase('provincia')}
            error={errors}
            inputDataQa="provincia-input"
            name="provincia"
            autofill="true"
          ></EaInput>
          </div>
          <div className="ea-input-box-two">
          <EaInput
            label={t(`${companyStorage}.baggage_delivery.codPostalLb`)}
            title={t(`${companyStorage}.baggage_delivery.codPostalT`)}
            idInput={"codigoPostal-input"}
            type="text"
            bindingField={bindingFieldNameToUpperCase('codigoPostal')}
            error={errors}
            inputDataQa="codigoPostal-input"
            name="codigoPostal"
            autofill="true"
          ></EaInput>
          <div className="ea-input">
            <EaSelect
              idInput={"pais-input"}
              label={t(`${companyStorage}.baggage_delivery.paisLb`)}
              name="pais"
              bindingField={bindingFieldName('pais')}
              listItems={listCountries}
              error={errors}
              value={props.baggage.countrySelected}
              inputDataQa="pais-select"
              disabled={true}
            ></EaSelect>
          </div>
          </div>

          <EaInput
            label={t(`${companyStorage}.baggage_delivery.dniLb`)}
            title={t(`${companyStorage}.baggage_delivery.dniT`)}
            idInput={"dni-input"}
            type="text"
            bindingField={bindingFieldNameToUpperCase('dni')}
            error={errors}
            inputDataQa="dni-input"
            name="dni"
            autofill="true"
          ></EaInput>

          <EaInput
            label={t(`${companyStorage}.baggage_delivery.telefonoLb`)}
            title={t(`${companyStorage}.baggage_delivery.telefonoT`)}
            idInput={"telefono-input"}
            type="text"
            bindingField={bindingFieldNameToUpperCase('telefono')}
            error={errors}
            inputDataQa="telefono-input"
            name="telefono"
            autofill="true"
          ></EaInput>

          <EaInput
            label={t(`${companyStorage}.baggage_delivery.emailLb`)}
            title={t(`${companyStorage}.baggage_delivery.emailT`)}
            idInput={"email-input"}
            type="text"
            bindingField={bindingFieldName('email')}
            error={errors}
            inputDataQa="email-input"
            name="email"
            autofill="true"
          ></EaInput>
          <p>{t(`${companyStorage}.baggage_delivery.preferenciaLb`)}</p>
          <div className="preferencia-box">
            <EaInput
              label={t(`${companyStorage}.baggage_delivery.preferenciaMañanaLb`)}
              title={t(`${companyStorage}.baggage_delivery.preferenciaMañanaT`)}
              idInput={"preferencia-input0"}
              type="radio"
              bindingField={bindingFielRadio('preferencia')}
              error={errors}
              inputDataQa="preferencia-input"
              name="preferencia"
              value="M"
            ></EaInput>
            <EaInput
              label={t(`${companyStorage}.baggage_delivery.preferenciatardeLb`)}
              title={t(`${companyStorage}.baggage_delivery.preferenciatardeT`)}
              idInput={"preferencia-input1"}
              type="radio"
              bindingField={bindingFielRadio('preferencia')}
              error={errors}
              inputDataQa="preferencia-input"
              name="preferencia"
              value="T"
            ></EaInput>
            <EaInput
              label={t(`${companyStorage}.baggage_delivery.preferenciaMañanatardeLb`)}
              title={t(`${companyStorage}.baggage_delivery.preferenciaMañanatardeT`)}
              idInput={"preferencia-input2"}
              type="radio"
              bindingField={bindingFielRadio('preferencia')}
              error={errors}
              value="TM"
              inputDataQa="preferencia-input"
              name="preferencia"
            ></EaInput>
          </div>
          <label title={t(`${companyStorage}.baggage_delivery.observacionesT`)}>{t(`${companyStorage}.baggage_delivery.observacionesLb`)}</label>
          <textarea
            id="observaciones"
            {...bindingFieldName('observaciones')}
            name="observaciones"
            data-qa="email-input"
          ></textarea>
          <div className="condiciones-box">
            <EaInput
              idInput={"condiciones-input"}
              type="checkbox"
              bindingField={bindingFielCheck('condiciones')}
              value="true"
              error={errors}
              inputDataQa="condiciones-input"
              name="condiciones"
              isTermsAndConditions={true}
              lang={props.user.language}
            ></EaInput>
          </div>
          <div className="btn-wrapper">
            <button data-qa="submit-pir" className="btn btn--primary" id="btn-user-continue" onClick={goBack}>{t('DEFAULT_BTN_VOLVER')}</button>
            <button data-qa="submit-delivery"className="btn btn--primary" id="btn-user-continue" disabled={!isValidForm()}>{t(`${companyStorage}.captura.continue`)}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IntStore) => {
  return {
    view: state.masterdata.view,
    baggage: state.baggage,
    user:state.user
  };
};

const mapDispatchToProps = {
  setView,
  showLoading,
  getListCountries,
  getPhones,
  postDeliveries,
  getCodePostal
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryBaggage);
