import React, { ReactElement, useEffect, Suspense } from 'react';
import i18next from 'i18next';
import { connect, useDispatch } from 'react-redux';
import { IntHomeProps } from '../../schemas/home.interface';
import { IntStore } from '../../schemas/store.interface';
import { getUserLang, setCompany, setUserLang, setValue } from '../../core/local-storage/local-storage.service';
import { setView } from '../../core/masterdata/masterdata.reducer';
import { useHistory } from 'react-router-dom';
import '../../core/i18n/i18n';
import MainHeader from '../../components/layout/main-header/main-header';
import Loading from '../../components/ui/loading/loading';
import useMasterdata from '../../hooks/use-masterdata';
import { setStep } from '../../components/layout/main-header/main-header.reducer';
import { setUserLanguage } from '../user/user.reducer';
import { environment } from '../../environments/environment';

const Home = (props: IntHomeProps): ReactElement => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { masterdataState, getLanguagesList, getLanguagesI18n, loadStyles } = useMasterdata(
    `${environment.endpoints.languages}`
  );

  useEffect(() => {

    if (props?.match?.params?.company) {
      setCompany(props.match.params.company);
    }
    loadStyles();
    getLanguagesList();
  }, []);

  useEffect(() => {
    if (masterdataState.languages && masterdataState.languages.length) {
      i18next.init({
        supportedLngs: getLanguagesI18n(),
        lng: getUserLang(),
      });
    }
  }, [masterdataState.languages]);

  useEffect(() => {
    if (masterdataState.stylesLoaded && !props.error.notFound) {

        if(!getUserLang()){
          setUserLang("es-ES");
          dispatch(setUserLanguage("es-ES"))
        }

        dispatch(setUserLanguage(getUserLang()))
        props.setView('pir');
        history.push('/' + props?.match?.params.company + '/pir');
        dispatch(setStep({step:1}));

    }
  }, [masterdataState.stylesLoaded]);

  useEffect(() => {
    if (props.error.notFound) {
      history.push('/oops');
    }
  }, [props.error.notFound]);

  return (
    <div className="root-wrapper">
      <Suspense fallback="">
        {/* Cabecera */}
        <MainHeader />
        {/* Loading general */}
        <Loading />
        {/* Error general */}
        {/* <Error /> */}
      </Suspense>
      {/* <footer className="footer">
        <p>© Iberia 2020 - All rigths reserverd</p>
      </footer>  */}
    </div>
  );
};

const mapStateToProps = (state: IntStore) => {
  return {
    error: state.error,
  };
};

const mapDispatchToProps = {
  setView,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
