import { IntActionsGeneral } from '../../schemas/actions.interface';
import { IntErrorReducer } from '../../schemas/error.interface';

/* ==================================================================
  TYPES
================================================================== */
export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';
export const SHOW_NOT_FOUND = 'SHOW_NOT_FOUND';

/* ==================================================================
  ACTIONS
================================================================== */
export const showError = (payload: IntErrorReducer): IntActionsGeneral => ({
  type: SHOW_ERROR,
  payload,
});
export const hideError = (): IntActionsGeneral => ({
  type: HIDE_ERROR,
});
export const showNotFound = (): IntActionsGeneral => ({
  type: SHOW_NOT_FOUND,
});

/* ==================================================================
  REDUCERS
================================================================== */
// InitialState
export const errorInitialState: IntErrorReducer = {
  show: false,
  msg: '',
  codeError:'',
  redirect: '',
  notFound: false,
};

// Reducer
export const error = (state = errorInitialState, action: IntActionsGeneral): IntErrorReducer => {
  switch (action.type) {
    case SHOW_ERROR:
      return {
        ...state,
        show: true,
        msg: action.payload.msg,
        codeError:action.payload.codeError,
        redirect: action.payload.redirect,
      };
    case HIDE_ERROR:
      return {
        ...state,
        show: false,
        msg: '',
        codeError:'',
        redirect: '',
      };
    case SHOW_NOT_FOUND:
      return {
        ...state,
        notFound: true,
      };
    default:
      return state;
  }
};
