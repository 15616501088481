import { useState } from 'react';


export type validationTypes =
    'REQUIRED' |
    'PATTERN' |
    'FUNCTION';

export enum AvailableValidations {
    REQUIRED = 'REQUIRED',
    PATTERN = 'PATTERN',
    FUNCTION = 'FUNCTION'
}

export const useFormArray = (validations: any = {}, initialState: any = {}) => {

    if (!validations) {
        throw new Error('the option `validations` is required');
    }

    if (typeof initialState !== 'object') {
        throw new Error('the option `initialValues` should be an object');
    }

    const [values, setValues] = useState(initialState);
    const initialStateArray: Array<Array<any>> = [];
    const [errorsArray, seterrorsArray] = useState(initialStateArray);

    const validateFieldInArray = (name: string, value: any, index, state) => {

        const rules = validations[name];

        if (!rules) {
            return state;
        }

        if (rules.type === AvailableValidations.REQUIRED) {
            if (!value || !value.trim()) {

                state[index] = state[index] ? state[index] : {};
                state[index][name] = {
                    isValid: false,
                    message: rules.message
                };
                return state;
            } else {
                state[index] = state[index] ? state[index] : {};
                state[index][name] = {
                    isValid: true,
                    message: ''
                };
                return state;
            }
        }

        if (rules.type === AvailableValidations.PATTERN) {
            if (!new RegExp(rules.value).exec(value)) {

                state[index] = state[index] ? state[index] : {};
                state[index][name] = {
                    isValid: false,
                    message: rules.message
                };
                return state;
            } else {
                state[index] = state[index] ? state[index] : {};
                state[index][name] = {
                    isValid: true,
                    message: ''
                };
                return state;
            }
        }


        if (rules.type === AvailableValidations.FUNCTION && typeof rules.value === 'function') {
            const { isValid } = rules.value(value);
            if (!isValid) {

                if (!value.trim()) {

                    state[index] = state[index] ? state[index] : {};
                    state[index][name] = {
                        isValid: false,
                        message: rules.message
                    };
                    return state;
                } else {
                    state[index] = state[index] ? state[index] : {};
                    state[index][name] = {
                        isValid: true,
                        message: ''
                    };
                    return state;
                }
            }
        }
    }

    const bindingFieldNameInArrayForm = (id, index, name) => {

        if (!name) {
            throw new Error('The field name parameter is required');
        }

        if (name && typeof name !== 'string') {
            throw new Error('The field name should be a string');
        }

        return {

            value: values[index][name] || '',


            onChange: (e) => {
                const { value } = e.target;
                setValues((state: any) => {

                    state = state.map(item => {
                        if (id === item.id) {
                            item[name] = value;
                            return item
                        }
                        return item
                    });

                    return [...state]
                });

                seterrorsArray((state: any) => {
                    return validateFieldInArray(name, value, index, state)
                });
            },
        }
    }

      const isValidForm = (totalItems) => {

        if(errorsArray.length !== totalItems) {

            let initialStateArray: Array<Array<any>> = [];
            values.map( item =>{
                initialStateArray.push([]);
            })

            values.forEach((item, index)=> {
               Object.keys(validations).forEach((name) =>{

                    if(!values[index][name]){
                        initialStateArray[index][name] = {
                            isValid: false,
                            message: ''
                        }
                    }else{
                        validateFieldInArray(name, values[index][name], index, errorsArray)
                    }
                })
            })

            seterrorsArray(initialStateArray);
        }

        let hasErrors = true;

        hasErrors = errorsArray.some((item:any) => {
            return Object.keys(item).some((name) => item[name]?.isValid === false);
        });

        return !hasErrors;
      }

    const reset = () => {
        setValues(initialState);
    }



    return {
        values,
        errorsArray,
        reset,
        isValidForm,
        bindingFieldNameInArrayForm
    };

}
