import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { getCompany } from '../../../core/local-storage/local-storage.service';
import { environment } from '../../../environments/environment';
import { getCodeCompany } from '../../../views/user/user.service';
// import styles from './ea-input.module.scss';

const EaInput = (props: any): ReactElement => {

  const { t } = useTranslation();
  const companyStorage = getCompany();
  const innerHtml =  () => {
    return {__html: t(`${props.inner}`)};
  }

  return (
    <div data-testid="eaInput" className='ea-input'>
      <div dangerouslySetInnerHTML={props.inner && innerHtml()}></div>
      {!props.isTermsAndConditions && (
        <label> {props.label} </label>
      )}
      {props.isTermsAndConditions && (
        <label>
          {t(`${companyStorage}.baggage_delivery.condicionesLegales_first`)}
            <a href={`${environment.static.pdfs}/${companyStorage}/${props.lang}/CondicionesLegales_${getCodeCompany(companyStorage)}.pdf`} target="_blank">{t(`${companyStorage}.baggage_delivery.condicionesLegales_second`)}</a>
            {t(`${companyStorage}.baggage_delivery.condicionesLegales_third`)}
        </label>
      )}
      <input
       title={props.title}
       id={props.idInput}
       type={props.type}
       value={props.value}
       placeholder={props.placeholder}
       {...props.bindingField}
       name={props.name}
       autoComplete={props.autofill?props.name + Math.random():"off"}
       data-qa={props.inputDataQa}
      />
       {!props.error?.[props.name]?.isValid && <span className="error">{ t(props.error?.[props.name]?.message)}</span>}
       {/* {Segunda validacion para array forms } */}
      {!props.error?.isValid && <span className="error">{ t(props.error?.message) }</span>}
    </div>
  );
};

export default EaInput;
