import React, { ReactElement, useEffect, useState } from 'react';
// import styles from './confirm-baggage.module.scss';
import { IntConfirmBaggageProps } from '../../../schemas/baggage.interface';
import { connect, useDispatch } from 'react-redux';
import { IntStore } from '../../../schemas/store.interface';
import { useHistory } from 'react-router-dom';
import { setView } from '../../../core/masterdata/masterdata.reducer';
import { getCompany } from '../../../core/local-storage/local-storage.service';
import { clearConfirm } from './confirm.reducer';
import { useTranslation } from 'react-i18next';
import { showLoading } from '../../../components/ui/loading/loading.reducer';
import { getDeliveris } from  './confirm-baggage.service';

const ConfirmBaggage = (props: IntConfirmBaggageProps): ReactElement => {

  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyStorage = getCompany();

  const [deliveryData, setDeliveryData]:any = useState({});

  useEffect(()=>{
    async function load() {
      props.showLoading();

      const payload = {
        pir: props.user.userForm.pir,
        recordNumber: props.user.recordNumber
      }

      const response = await props.getDeliveris(payload,history);
      setDeliveryData(response.data);
    }
    load();
  },[])

  const eventInitBaggage = () => {

    props.setView('user');
    dispatch(clearConfirm());
    history.push('/' + getCompany() + '/pir');
  };


  return (
    <div data-testid="confirmbaggage">
        <div className="card">
          <p>{t(`${companyStorage}.baggage_confirm.title`)}</p>
          <div className="resume">
            <p>
              <span>{t(`${companyStorage}.baggage_confirm.domicilio`)}</span>
              <span>{deliveryData.homeAddress}</span>
            </p>
            <p>
              <span>{t(`${companyStorage}.baggage_confirm.localidad`)}</span>
              <span>{deliveryData.locality}</span>
            </p>
            <p>
              <span>{t(`${companyStorage}.baggage_confirm.pais`)}</span>
                <span>{deliveryData.country}</span>
            </p>
            <p>
              <span>{t(`${companyStorage}.baggage_confirm.tfno`)}</span>
              <span>{deliveryData.phone}</span>
            </p>
            <p>
              <span>{t(`${companyStorage}.baggage_confirm.e-mail`)}</span>
              <span>{deliveryData.email}</span>
            </p>
            <p>
              <span>{t(`${companyStorage}.baggage_confirm.preferencia_de_entrega`)}</span>
              <span>{deliveryData.preference}</span>
            </p>
            <p>
              <span>{t(`${companyStorage}.baggage_confirm.observaciones_adicionales`)}</span>
              <span>{deliveryData.difficulty}</span>
            </p>
          </div>
          <br/>
          <br/>
          <b>
              <span>{t(`${companyStorage}.baggage_confirm.deteriorado`)}</span>
            </b>
          <div className="btn-wrapper">
            <button className="btn btn--primary" id="volver-baggage" onClick={() => eventInitBaggage()}>
            {t(`${companyStorage}.baggage_confirm.btn_volver`)}
            </button>
          </div>
        </div>
    </div>
  );
};

const mapStateToProps = (state: IntStore) => {
  return {
    view: state.masterdata.view,
    confirm: state.confirm,
    user:state.user
  };
};

const mapDispatchToProps = {
  setView,
  showLoading,
  getDeliveris
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmBaggage);
