import axios from 'axios';
import { hideLoading } from '../../../components/ui/loading/loading.reducer';
import { getCompany } from '../../../core/local-storage/local-storage.service';
import { environment } from '../../../environments/environment';
import { showError } from '../../error/error.reducer';

export const getBaggages = (payload, useHistory) => {

    const companyStorage = getCompany();
    const params = new URLSearchParams(payload);

    return (dispatch: any) => {
        return axios
            .get(environment.endpoints.getBaggagesCarousel , {params}) // environment.endpoints.listCountries
            .then((response) => {
                if(!response || !response.data || !response.data.data || !response.data.data.datos){
                    console.log('getBaggages:NO RESPONDIO CON LA ESTRUCTURA ESPERADA ');
                    return;
                }

                return response.data.data.datos
            })
            .catch((error) => {
                dispatch(showError({ msg: 'ERROR: LANGUAGE_SERVICE', redirect: `${companyStorage}/`, codeError: 'ERR000001' }));
                useHistory.push(`/${companyStorage}/error`);
            }).finally(() => {
                dispatch(hideLoading());
            })
    };
};




//  endpoint salida ---> post
//  _root.ArrayMaletas[i].ide + "."
//  + _root.ArrayMaletas[i].colcode + "-";
export const postBaggages = (payload,useHistory) => {
    const url  =  environment.endpoints.suitcases + '/' + payload.lossAgentParentId
    const companyStorage = getCompany();
    return (dispatch: any) => {
        return axios
            .post(url , [...payload.suitcases])
            .then((response) => {
                if(!response || !response.data){
                    console.log('postBaggages:NO RESPONDIO CON LA ESTRUCTURA ESPERADA ');
                    return;
                }
                if(response.data.error.isError){
                    dispatch(showError({ msg: 'ERROR: BAGGAGE_SERVICE getDprs', redirect: `${companyStorage}/baggage/selection`, codeError: 'ERR000001' }));
                    return;
                }
                return response.data
            })
            .catch((error) => {
                dispatch(showError({ msg: 'ERROR: LANGUAGE_SERVICE', redirect: `${companyStorage}/baggage/selection`, codeError: 'ERR000001' }));
                useHistory.push(`/${companyStorage}/error`);
            }).finally(() => {
                dispatch(hideLoading());
            })
    };
};




/**
 *  redirect a reembolso o tiendas
 */
export const cancelBaggage = (payload, useHistory) => {
    const companyStorage = getCompany();

    const url = environment.endpoints.suitcases + '/' + payload.deliveryCountry + '/' + payload.firm + '/' + payload.pir;

    return (dispatch: any) => {
        return axios
            .post(url) // environment.endpoints.listCountries
            .then((response) => {
                if(!response || !response.data ){
                    console.log('getBaggages:NO RESPONDIO CON LA ESTRUCTURA ESPERADA ');
                    return;
                }

                return response.data
            })
            .catch((error) => {
                dispatch(showError({ msg: 'ERROR: LANGUAGE_SERVICE', redirect: `${companyStorage}/`, codeError: 'ERR000001' }));
                useHistory.push(`/${companyStorage}/error`);
            }).finally(() => {
                dispatch(hideLoading());
            })
    };
};
