import { connect, useDispatch } from 'react-redux';
import React, { ReactElement, Suspense, useEffect, useState } from 'react';
import { IntStore } from '../../schemas/store.interface';
import { useHistory } from 'react-router';
import { getStoreList , getStoreInformation ,getDpr,getCountry , postStores }from './choose-store.service';
import { InitChooseStore }from '../../schemas/choose-store.interface';
import {showLoading} from '../../components/ui/loading/loading.reducer';
import { formatDate, getCodeCompany } from '../user/user.service';
import { getCompany } from '../../core/local-storage/local-storage.service';
import { useTranslation } from 'react-i18next';
import { setIsSotoreSelected } from '../baggage/baggabe.reducer';
import { environment } from '../../environments/environment';

const ChooseStore = (props: InitChooseStore): ReactElement => {

  const { t } = useTranslation();
  const companyStorage = getCompany();
  const history = useHistory();
  const dispatch = useDispatch();
  const [storeList, setstoreList] = useState([])
  const [isStoreSelect, setisStoreSelect] = useState(false);
  const [isStoreSelectProc, setisStoreSelectProc] = useState(false);
  const [dataStore, setdataStore]:any = useState({});
  const [country, setCountry] = useState('')

  useEffect(() => {

    async function load() {
      props.showLoading();
      const storelistResponse = await props.getStoreList(props.user.lossAgentParentId, history);
      const payloadGetCountry = {
        lossAgentParentId:props.user.lossAgentParentId,
        language:props.user.language.slice(3, 5),
      }
      const responseCountry = await props.getCountry(payloadGetCountry, history);
      setCountry(responseCountry.data.name);

      const payloadDprs = {
        Pir: props.user.userForm.pir,
        PirDate:formatDate(props.user.userForm.fechaIncidencia),
        Firm:getCodeCompany(companyStorage),
        User:props.user.userForm.apellido,
      }
      const responseDpr = await props.getDpr(payloadDprs, history);

      if(responseDpr && responseDpr.data && responseDpr.data.hasOwnProperty('homeAddress')){
        setisStoreSelect(true);
      }
      setstoreList(storelistResponse);
    }
    if(!props.baggage.isStoreSelected){
      load();
    }else{
      setdataStore(props.baggage.pir_redirect);
      setisStoreSelect(true);
    }
  }, []);


  const storeSelected = async (e,item) => {
    e.preventDefault();
    if (isStoreSelectProc==false)
    {
      setisStoreSelectProc(true);
      const storeResponse = await props.getStoreInformation(props.user.lossAgentParentId , item.borrowerAssistanceId , history );
      const payload = {
        lossAgentParentId: props.user.lossAgentParentId,
        pir: props.user.userForm.pir,
        data: {
          ...storeResponse
        }
      }

      const postStoresResponse = await props.postStores(payload , history );
      setdataStore(postStoresResponse);
      setisStoreSelect(true);
      dispatch(setIsSotoreSelected(true));
      setisStoreSelectProc(false);
    }
  }

  const closeStore = () => {
    history.push('/' + companyStorage + '/pir');
  }

  const vlg_rlink = t(`${companyStorage}.choose_store.refund_link_url`);
  const def_rlink = `${environment.static.pdfs}/${companyStorage}/${props.user.language}/reembolso_${getCodeCompany(companyStorage)}.pdf`;

  return (
    <Suspense fallback="">
      {!isStoreSelect &&(
        <div className="card choose-store">
            {t(`${companyStorage}.choose_store.title_first`)} <b> {country} </b>
            {t(`${companyStorage}.choose_store.title_second`)}
        <ul className="store-list">
        {storeList && storeList.map((item:any, index)=>(
            <li className="store-item" key={item.borrowerAssistanceId + '_' + index } onClick={(e)=>storeSelected(e,item)}>
              <p className="store-address">{item.address}</p>
              <p className="commercialName">{item.commercialName}</p>
              <p className="homeAddress">{item.homeAddress} - {item.town}</p>
              <p className="phone">{item.phone}</p>
            </li>
        ))}
        </ul>
          <p>
            {t(`${companyStorage}.choose_store.refund`)}
            <a href={{ 'vueling': vlg_rlink }[companyStorage] || def_rlink} target="_blank">
              {t(`${companyStorage}.choose_store.refund_link`)}
            </a>
          </p>
         <div className="btn-wrapper">
              <button data-qa="submit-pir" className="btn btn--primary" id="btn-user-continue" onClick={()=>closeStore()}>{t(`${companyStorage}.choose_store.cancel`)}</button>
          </div>
      </div>
      )}





      {isStoreSelect && (
        <div className="card choose-store">

          {props.baggage.pir_redirect.isPirRedirect && (
            <div>
              <h5> {t(`${companyStorage}.choose_store.pir_redirect_title_first`)}</h5>
              <br/>
              <h5> {t(`${companyStorage}.choose_store.pir_redirect_title_second`)}</h5>
             <br/>
             <h5> {t(`${companyStorage}.choose_store.pir_redirect_title_third`)}</h5>
            </div>
          )}

          <div> {t(`${companyStorage}.choose_store.provider`)}<b>{dataStore.supplier}</b></div>
          <div>{t(`${companyStorage}.choose_store.situated_in`)}  <b>{dataStore.homeAddress}</b></div>
          <div>{t(`${companyStorage}.choose_store.locale`)}  <b>{dataStore.city}</b></div>
          <div>{t(`${companyStorage}.choose_store.phone`)}  <b>{dataStore.phone}</b></div>

          <div>{t(`${companyStorage}.choose_store.detail`)}</div>

          <ul>
            <li> {t(`${companyStorage}.choose_store.referenceNumber`)} <b>{dataStore.wo}</b></li>
            <li> {t(`${companyStorage}.choose_store.pir`)}</li>
            <li> {t(`${companyStorage}.choose_store.originalTag`)}</li>
            <li> {t(`${companyStorage}.choose_store.originalTarjet`)}</li>
            <li> {t(`${companyStorage}.choose_store.DNI`)}</li>
            <li> {t(`${companyStorage}.choose_store.baggages`)}</li>
          </ul>

          <div className="btn-wrapper">
            <button data-qa="submit-pir" className="btn btn--primary" id="btn-user-continue" onClick={closeStore}>{t(`DEFAULT_BTN_VOLVER`)}</button>
          </div>
        </div>
      )}

    </Suspense>
  );
};

const mapStateToProps = (state: IntStore) => {
  return {
    user:state.user,
    baggage:state.baggage
  };
};

const mapDispatchToProps = {
  getStoreList,
  showLoading,
  getStoreInformation,
  getDpr,
  getCountry,
  postStores
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseStore);
