import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from '../../views/home/home';
import User from '../../views/user/user';
// import LanguageList from '../../views/language-list/language-list';
import Baggage from '../../views/baggage/baggage';
import SelectionBaggage from '../../views/baggage/selection-baggage/selection-baggage';
import DeliveryBaggage from '../../views/baggage/delivery-baggage/delivery-baggage';
import ConfirmBaggage from '../../views/baggage/confirm-baggage/confirm-baggage';
import Error from '../../views/error/error';
import NotFound from '../../views/not-found/not-found';
import DescriptionBaggage from '../../views/baggage/description-baggage/description-baggage'
import Refund from '../../views/refund/refund';
import ContactUs from  '../../views/contact-us/contact-us';
import ChooseStore from  '../../views/choose-store/choose-store';


const Root = () => (
  <Router>
    {/* <Switch> */}
    <Route path="/:company" component={Home} />
    {/* <Route path="/:company/languages" component={LanguageList} /> */}
    <Route path="/:company/error" component={Error} />
    <Route path="/:company/pir" component={User} />
    <Route path="/:company/refund" component={Refund} />
    <Route path="/:company/contact-us" component={ContactUs} />
    <Route path="/:company/choose-store" component={ChooseStore} />
    <Route exact path="/:company/baggage" component={Baggage} />
    <Route exact path="/:company/baggage/description" component={DescriptionBaggage} />
    <Route exact path="/:company/baggage/confirm" component={ConfirmBaggage} />
    <Route exact path="/:company/baggage/delivery" component={DeliveryBaggage} />
    <Route exact path="/:company/baggage/selection" component={SelectionBaggage} />
    <Route path="/:company/oops" component={NotFound} />
    {/* </Switch> */}
  </Router>
);

export default Root;
