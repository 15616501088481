import axios from 'axios';
import { useReducer } from 'react';
import {
  setListLanguages,
  setStylesLoaded,
  masterdata,
  masterdataInitialState,
} from '../core/masterdata/masterdata.reducer';
import { showError, showNotFound } from '../views/error/error.reducer';
import { useDispatch } from 'react-redux';
import { IntLanguage } from '../schemas/masterdata.interface';
import { getCompany } from '../core/local-storage/local-storage.service';
// const listaPaises = require('../assets/mocks/listaPaises.json');

const useMasterdata = (url: string) => {
  const [masterdataState, masterdataDispatch] = useReducer(masterdata, masterdataInitialState);

  const dispatch = useDispatch();

  const getLanguagesList = async() => {
    try {

      const response = await axios.get(url)
      .then((res:any)=>res.data)
      .catch(e => {
        console.error('No se ha podido recuperar la lista de paises ')
        return;
      });

      const MappedResponse = await  response.map((item:any)=>{
        item = {
          key :item.code.slice(0,2),
          value :item.name
        }
        return item
      })


      await masterdataDispatch(setListLanguages(MappedResponse));
    } catch (e) {
      dispatch(showError({ msg: 'Error Custom Hooks' }));
    }
  };

  const getLanguagesI18n = () => {
    const keyLanguage: string[] = [];
    const stateLanguages = masterdataState.languages;
    stateLanguages.forEach((lang: IntLanguage) => {
      keyLanguage.push(lang.key);
    });
    return keyLanguage;
  };

  const loadStyles = () => {
    const companySelected = getCompany();
    if (companySelected) {
      try {
        require('../assets/companies/theme.scss');
        const body = document.body;
        body.classList.add(companySelected);

        masterdataDispatch(setStylesLoaded());
      } catch (error) {
        console.log("LOAD_STYLES ::: ERROR" ,  error )
        dispatch(showNotFound());
      }
    }
  };

  return { masterdataState, getLanguagesList, getLanguagesI18n, loadStyles };
};

export default useMasterdata;
