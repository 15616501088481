import { getListCodePostal } from '../core/local-storage/local-storage.service';

export const validateEmail = (email, message) => {

    if(!email){
        return requiredValue();
    }
    
    const regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    return {
        isValid : regex.test(email) ? true : false,
        message: message
    };
}

export const validateConditions = (conditions) =>{
    return {
         isValid : conditions,
        message: 'DEFAULT_ERROR_REQUIRED'}
}


const requiredValue = () => {
         return {
            isValid : false,
            message: 'DEFAULT_ERROR_REQUIRED'
        };
}

export const checkCodePostalIslands = (codePostal, message) => {

    if(!codePostal){
        return requiredValue();
    }    

    const listCodeIslands = JSON.parse(getListCodePostal());    
    let valid=!listCodeIslands.includes(codePostal);    
    
    return {
            isValid : valid,
            message: 'DEFAULT_ERROR_POSTAL'
    };    
      
}