
export const listHeader = (company:string) => [
  {
    numberStep : 1,
    nameStep: `${company}.header.datos_personales`,
    path: `/${company}/pir`,
    key:'item_header_1',
  },
  {
    numberStep : 2,
    nameStep:`${company}.header.seleccion_maleta`,
    path:[ `/${company}/baggage` , `/${company}/baggage/description` ,`/${company}/baggage/selection`],
    key:'item_header_2',
  },
  {
    numberStep : 3,
    nameStep: `${company}.header.direccion_envio`,
    path: [`/${company}/baggage/delivery` , `/${company}/choose-store`],
    key:'item_header_3',
  },
  {
    numberStep : 4,
    nameStep: `${company}.header.finalizar`,
    path: [`/${company}/baggage/confirm` ,`/${company}/contact-us` ],
    key:'item_header_4',
  }
]


