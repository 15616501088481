import React, { ReactElement } from 'react';

const LanguageElement = (props: any): ReactElement => {
  return (
    <div  className="language-element" data-testid="languageElement">
      <div  data-qa={props.idLang} id={props.idLang} onClick={() => props.handleSelectLanguage(props.idLang)}>
        <span></span><span>{props.label}</span>
      </div>
    </div>
  );
};

export default LanguageElement;
