
import React, { ReactElement, useEffect, useState } from 'react';
import i18next from 'i18next';
import { useHistory } from 'react-router-dom';
import { setUserLanguage } from '../../views/user/user.reducer';
import { connect, useDispatch } from 'react-redux';
import { IntLanguageProps } from '../../schemas/language.interface';
import { IntStore } from '../../schemas/store.interface';
import LanguageElement from '../../components/ui/language-element/language-element';
import { setUserLang, getUserLang } from '../../core/local-storage/local-storage.service';
import { setListLanguages, setView } from '../../core/masterdata/masterdata.reducer';
import { hideLoading, showLoading } from '../../components/ui/loading/loading.reducer';
import {getLanguageList} from './language-list.service'
import { getImages } from '../../utils/const';


const LanguageList = (props: IntLanguageProps): ReactElement => {

  const dispatch = useDispatch()
  const history = useHistory();
  const [dataLanguage , setDataLanguage] = useState([]);

  const [displayLanguage, setDisplayLanguage] = useState(false);
  const [idLang, setidLang] = useState(getUserLang());
  const [idLanguage, setidLanguage] = useState('');


  useEffect(() => {
    async function load() {
      props.showLoading();
      const languague = await props.getLanguageList(history);

      if(!languague || !languague.length){
        return;
      }

      const lang = languague.find((lang:any) => lang.code === idLang);
      setidLanguage(lang.name);
      dispatch(setListLanguages(languague))
      setDataLanguage(languague);
    }
    load();
  }, []);


  const handleSelectLanguage = (name: string, idLang?: any) => {
    props.setUserLanguage(idLang);
    setUserLang(idLang); // Para actualización de userLang en Footer
    i18next.changeLanguage(idLang).then((t) => {

      setUserLang(idLang);
      setidLang(idLang)
      props.hideLoading();
      setDisplayLanguage(false);
      setidLanguage(name);
    });
  };




  return (
    <div className="">
      <div data-testid="languageList">

            <div className="language-header" onClick={()=>setDisplayLanguage(true)}>
              <img className="language-flag" src={getImages(idLang)} />
              <span className="language-country">{idLanguage}</span>
            </div>

            <div  className="list--container">
                {dataLanguage && dataLanguage.length && displayLanguage &&
                 dataLanguage.map((item:any, index) => (
                  <LanguageElement
                    key={index + '-'+ item.code}
                    idLang={item.code}
                    label={item.name}
                    handleSelectLanguage={(idLang: any) => handleSelectLanguage(item.name, idLang)}
                  />
              ))}
            </div>

      </div>
    </div>

  );
};

const mapStateToProps = (state: IntStore) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = {
  setUserLanguage,
  setView,
  hideLoading,
  showLoading,
  getLanguageList
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageList);
