import { getCompany } from "../core/local-storage/local-storage.service";
import { DefaultRawData } from "../schemas/defaultResponse.interface";
import { setIsSotoreSelected, setRedirectPir } from "../views/baggage/baggabe.reducer";
import { setBaggageData } from "../views/baggage/confirm-baggage/confirm.reducer";
import { showError } from "../views/error/error.reducer";
import { setBaggageDataPir, setCalculateCategory, setReason } from "../views/user/user.reducer";


const urls = {
   bagage: '/baggage',
   bagage_confirm: '/baggage/confirm',
   bagage_delivery: '/baggage/delivery',
   bagage_selection: '/baggage/selection',
   refund: '/refund',
   contact_us: '/contact-us',
   choose_store: '/choose-store'
};

/** Mapper respuesta Pir
 *
 *  Respuesta ejemplo success:
 *
 *
 * {"data": {
 *       "recordNumber":"555",
 *       "isVIPClient":"S"
 *     },"redirect":{
 *          "pageTo":"/baggage"
 *      },"error":{
 *          "isError":false,
 *          "errorCode":""
 *      }
 * }
 *
 *
 *  Tenemos 3 casuisticas caso success
 *
 *      1. continuamos flujo completo.
 *      2. continuamos a delivery
 *      3. continuamos a confirm
 *
 * En cualquier caso de succes dependiendo a la pagina a la que nos tengamos que dirigir
 * dispatcheamos al store de la pagina pertinente una data u otra
 *
 *  Ejemplo rawdata Error , en caso de que back falle nos devolvera un 200 en la request
 *  pero nos devolvera el error a true con un codigo. ese codigo lo traducimos. para mostrar los mensajes
 *  de error en el front.
 *
 *
 * * {"data": {}
 *     ,"redirect":{
 *          "pageTo":"" | page "error"
 *      },"error":{
 *          "isError":true,
 *          "errorCode":"ERR_0000001"
 *      }
 * }
 *
 *
 * Comprobamos si hay error o no , y mapeamos la respuesta dependiendo de la url a la que nos dirije
 * ya que esperamos unos datos u otros dependiendo de la rawdata
 * En esta funcion mapearemos o adaptaremos los datos de back para mejor usabilidad en el front.
 *
 *
 */

export const parseRawdata = (rawdata:DefaultRawData  , dispatch:any , payload:any) => {


    let mappedResponse:any = {}
    if(rawdata.error.isError){
        mappedResponse.error = true;
        mappedResponse.errorCode = rawdata.error.code ? rawdata.error.code : 'ERR000001'
        return mappedResponse;
    }

    if(rawdata.redirect.pageTo === urls.bagage || rawdata.redirect.pageTo === urls.bagage_delivery) {
     dispatch(setBaggageDataPir({...rawdata.data}))
     return rawdata.redirect.pageTo;
    }

    /**
     * Retornaremos al storage dela pagina baggageDelibery los datos que presentamos al usuario
     * TODO. añadir al storage baggage-confirm el modelo
     */
    if(rawdata.redirect.pageTo === urls.choose_store){
        dispatch(setRedirectPir(rawdata.data));
        dispatch(setIsSotoreSelected(true));
        return rawdata.redirect.pageTo;
    }


    if(rawdata.redirect.pageTo === urls.contact_us){
        return rawdata.redirect.pageTo;
    }

    if(rawdata.redirect.pageTo === urls.bagage_confirm){
     dispatch(setBaggageDataPir({...rawdata.data , isVIPClient:false}))

     return rawdata.redirect.pageTo;
    }
}


export const parseRawdataDescription = (rawdata  , dispatch:any , history:any) => {
    const companyStorage = getCompany();

    if(!rawdata || !rawdata.data || !rawdata.redirect){
        return;
    }

    if(rawdata.data.hasOwnProperty('calculateCategory')){
       dispatch(setCalculateCategory(rawdata.data.calculateCategory))
    }

    if(rawdata.data.hasOwnProperty('reason')){
        dispatch(setReason(rawdata.data.reason))
    }

    history.push(`/${companyStorage}${rawdata.redirect.pageTo}`);
    // history.push('/' + getCompany() + '/baggage/selection');

    // return rawdata.redirect.pageTo
}


const parseDefaultError = (dispatch:any , rawdata, useHistory , redirect , serviceError?:string) => {
    const companyStorage = getCompany();
    let codeError = '';
    if(rawdata && rawdata.error){
      codeError = rawdata.error.code ? rawdata.error.code : 'ERR000001'
      dispatch(showError({ msg: `ERROR: ${serviceError} `, redirect: `${companyStorage}/${redirect}`, codeError:  codeError}));
      useHistory.push(`/${companyStorage}/error`);
      return true;
    }
    return false;
}
