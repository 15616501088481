import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { user } from '../../views/user/user.reducer';
import { masterdata } from '../masterdata/masterdata.reducer';
import { loading } from '../../components/ui/loading/loading.reducer';
import { error } from '../../views/error/error.reducer';
import { header } from '../../components/layout/main-header/main-header.reducer'
import { confirm } from '../../views/baggage/confirm-baggage/confirm.reducer';
import { baggage } from '../../views/baggage/baggabe.reducer'

export const myHistory = createBrowserHistory({ basename: '/' });
let middlewares = [thunkMiddleware, routerMiddleware(myHistory)];
const devToolsOptions = {
  trace: true,
};
const composeEnhancers = composeWithDevTools(devToolsOptions);

const reducer = combineReducers({
  error,
  loading,
  user,
  masterdata,
  header,
  confirm,
  baggage
});

const store = createStore(reducer, {}, composeEnhancers(applyMiddleware(...middlewares)));
export default store;
