import { connect, useDispatch } from 'react-redux';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IntStore } from '../../schemas/store.interface';
import { hideError } from './error.reducer';
import { IntErrorProps } from '../../schemas/error.interface';

const Error = (props: IntErrorProps): ReactElement => {


  const html = document.getElementsByTagName('html')[0];
  html.classList.add("error-page");

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();

  const eventCloseError = (): void => {
    if (!props.error.redirect || props.error.redirect === '') {
      props.hideError();
    } else {
      history.push('/' + props.error.redirect);
    }
    dispatch(props.hideError());
    html.classList.remove("error-page");
  };

  const innerHtml =  () => {
    return {__html: t(`${props.error.codeError}`)};
  }

  useEffect(()=>{
    console.log(props.error?.msg);
  },[props.error])

  return (
    <div className="message message--error" data-testid="error">
      {props.error?.show && (
        <div>
          <div dangerouslySetInnerHTML={innerHtml()}></div>
          <button id="btn-close-error" onClick={() => eventCloseError()}>
           {t('BTN_ERR_VOLVER')}
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: IntStore) => {
  return {
    error: state.error,
  };
};

const mapDispatchToProps = {
  hideError,
};

export default connect(mapStateToProps, mapDispatchToProps)(Error);
