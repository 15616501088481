import axios from 'axios';
import { hideLoading } from '../../../components/ui/loading/loading.reducer';
import { getCompany } from '../../../core/local-storage/local-storage.service';
import { environment } from '../../../environments/environment';
import { showError } from '../../error/error.reducer';

export const getListCountries = (payload: any, useHistory: any) => {

    const params = new URLSearchParams(payload);
    const companyStorage = getCompany();

    return (dispatch: any, getState: any) => {
        return axios
            .get(environment.endpoints.pirs, { params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                dispatch(showError({ msg: 'ERROR: USER_SERVICE', redirect: `${companyStorage}/pir`, codeError: 'ERR000001' }));
                useHistory.push(`/${companyStorage}/error`);
            }).finally(()=>{
                dispatch(hideLoading());
            })
    };
};

export const getCodePostal = (useHistory: any) => {
    
    const companyStorage = getCompany();

    return (dispatch: any, getState: any) => {
        return axios
            .get(environment.endpoints.getCodePostalIslands)
            .then((response) => {
                return response.data;
            }).catch((error) => {
                dispatch(showError({ msg: 'ERROR: USER_SERVICE', redirect: `${companyStorage}/pir`, codeError: 'ERR000001' }));
                useHistory.push(`/${companyStorage}/error`);
            }).finally(()=>{
                dispatch(hideLoading());
            })
    };
};

export const getPhones = (recordNumber , useHistory) => {

    // const params = new URLSearchParams({recordNumber:recordNumber});
    const url = environment.endpoints.phones + '/' + recordNumber;
    const companyStorage = getCompany();

    return (dispatch: any, getState: any) => {
        return axios
            .get(url)
            .then((response) => {
                return response.data;
            }).catch((error) => {
                dispatch(showError({ msg: 'ERROR: USER_SERVICE', redirect: `${companyStorage}/pir`, codeError: 'ERR000001' }));
                useHistory.push(`/${companyStorage}/error`);
            }).finally(()=>{
                dispatch(hideLoading())
            })
    };
};


export const postDeliveries = (payload: any, useHistory: any) => {
    // const params = new URLSearchParams(payload);
    const companyStorage = getCompany();

    return (dispatch: any, getState: any) => {
        return axios
            .post(environment.endpoints.deliveries, payload)
            .then((response) => {
                return response.data;
            }).catch((error) => {
                dispatch(showError({ msg: 'ERROR: USER_SERVICE', redirect: `${companyStorage}/pir`, codeError: 'ERR000001' }));
                useHistory.push(`/${companyStorage}/error`);
            }).finally(()=>{
                dispatch(hideLoading());
            })
    };
};






