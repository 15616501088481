import { IntActionsGeneral } from '../../../schemas/actions.interface';
import { IntHeaderReducer } from '../../../schemas/header.interface';

/* ==================================================================
  TYPES
================================================================== */
export const SET_STEP = 'SET_STEP';

/* ==================================================================
  ACTIONS
================================================================== */
export const setStep = (payload:IntHeaderReducer): IntActionsGeneral => ({
  type: SET_STEP,
  payload
});


/* ==================================================================
  REDUCERS
================================================================== */
// InitialState
export const headerInitialState: IntHeaderReducer = {
  step:0
};

// Reducer
export const header = (
  state = headerInitialState,
  action: IntActionsGeneral
): IntHeaderReducer => {
  switch (action.type) {
    case SET_STEP:
      return {
        ...state,
        step:action.payload.step
      };
    default:
      return state;
  }
};
