import { Reducer } from 'react';
import { IntMasterdataReducer } from '../../schemas/masterdata.interface';
import { IntActionsGeneral } from '../../schemas/actions.interface';

/* ==================================================================
  TYPES
================================================================== */
export const SET_MASTERDATA_LANGUAGES = 'SET_MASTERDATA_LANGUAGES';
export const SET_VIEW = 'SET_VIEW';
export const STYLES_LOADED = 'STYLES_LOADED';

/* ==================================================================
  ACTIONS
================================================================== */
export const setListLanguages = (payload): IntActionsGeneral => ({
  type: SET_MASTERDATA_LANGUAGES,
  payload: payload,
});
export const setView = (payload: string): IntActionsGeneral => ({
  type: SET_VIEW,
  payload: payload,
});
export const setStylesLoaded = (): IntActionsGeneral => ({
  type: STYLES_LOADED,
});

/* ==================================================================
  REDUCERS
================================================================== */
// InitialState
export const masterdataInitialState: IntMasterdataReducer = {
  languages: [],
  view: 'language',
  stylesLoaded: false,
};

// Reducer
export const masterdata: Reducer<IntMasterdataReducer, IntActionsGeneral> = (
  state = masterdataInitialState,
  action: IntActionsGeneral
): IntMasterdataReducer => {
  switch (action.type) {
    case SET_MASTERDATA_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      };
    case SET_VIEW:
      return {
        ...state,
        view: action.payload,
      };
    case STYLES_LOADED:
      return {
        ...state,
        stylesLoaded: true,
      };
    default:
      return state;
  }
};
