import axios from 'axios';
import { hideLoading } from '../../../components/ui/loading/loading.reducer';
import { getCompany } from '../../../core/local-storage/local-storage.service';
import { environment } from '../../../environments/environment';
import { showError } from '../../error/error.reducer';

export const getDeliveris = (payload: any, useHistory: any) => {

  const url = environment.endpoints.deliveries + '/' + payload.recordNumber + '/' + payload.pir;
  const companyStorage = getCompany();

  return (dispatch: any, getState: any) => {
    return axios
      .get(url)
      .then((response) => {
        if(!response || !response.data){
          console.log('GET_DELIVERIS :  NO RESPONDIO COMO SE ESPERABA')
          return;
        }
        return response.data;
      }).catch((error) => {
        dispatch(showError({ msg: 'ERROR: USER_SERVICE', redirect: `${companyStorage}/pirs`, codeError: 'ERR000001' }));
        useHistory.push(`/${companyStorage}/error`);
      }).finally(() => {
        dispatch(hideLoading());
      })
  };
};

