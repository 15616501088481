import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './core/store/store';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import Root from './core/routes/routes';
import { initInterceptor } from './core/rest/interceptor';
import 'es6-promise/auto';
import 'core-js';
import 'react-app-polyfill/ie9';
import { loadLanguage } from './core/services/core.service';
/* Styles plugins */
import '@brainhubeu/react-carousel/lib/style.css';
import  Footer  from './views/footer/footer';
import { deleteByKey } from './core/local-storage/local-storage.service';

deleteByKey('AccessToken');

initInterceptor();

// Comprobamos y cargamos el idioma
loadLanguage();

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback="">
      <div className="content-wrapper">
        <Root />
      </div>
      <Footer />
    </Suspense>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
