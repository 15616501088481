import { debug } from 'console';
import { useState } from 'react';


export type validationTypes =
  'REQUIRED' |
  'PATTERN' |
  'FUNCTION';

export enum AvailableValidations {
  REQUIRED = 'REQUIRED',
  PATTERN = 'PATTERN',
  FUNCTION = 'FUNCTION'
}

const validateNameBinding = (name: string) => {
  if (!name) {
    throw new Error('The field name parameter is required');
  }
  if (name && typeof name !== 'string') {
    throw new Error('The field name should be a string');
  }
}

export const useForm = (validations, initialState: any = {}) => {

  if (!validations) {
    throw new Error('the option `validations` is required');
  }

  if (typeof initialState !== 'object') {
    throw new Error('the option `initialValues` should be an object');
  }

  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});

  const validateField = (name: string, value: any) => {
    const rules = validations[name];

    if (!rules) {
      return;
    }

    if (rules.type === AvailableValidations.REQUIRED) {
      if (!value.trim()) {
        return {
          isValid: false,
          message: rules.message
        };
      }
    }


    if (rules.type === AvailableValidations.PATTERN) {
      if (!new RegExp(rules.value).exec(value)) {
        return {
          isValid: false,
          message: rules.message
        }
      }
    }

    if (rules.type === AvailableValidations.FUNCTION && typeof rules.value === 'function') {
      const { isValid, message } = rules.value(value , rules.message);
      if (!isValid) {
        return {
          isValid,
          message
        };
      }
    }
  }


  const bindingFieldName = (name) => {

    validateNameBinding(name);

    return {

      value: values[name] || '',
      onChange: (e) => {
        const { value } = e.target;

        setValues(state => ({
          ...state,
          [name]: value,
        }));

        setErrors(state => ({
          ...state,
          [name]: validateField(name, value),
        }));
      },
    }
  }

  const bindingFielRadio = (name) => {
    validateNameBinding(name);

    return {
      onChange: (e) => {
        const value = e.target.value;
        setValues(state => ({
          ...state,
          [name]: value,
        }));

        setErrors(state => ({
          ...state,
          [name]: validateField(name, value),
        }));
      },
    }
  }


  const bindingFielCheck = (name) => {
    validateNameBinding(name);

    return {
      onChange: (e) => {
        const value = e.target.checked;
        setValues(state => ({
          ...state,
          [name]: value,
        }));

        setErrors(state => ({
          ...state,
          [name]: validateField(name, value),
        }));
      },
    }
  }

  const bindingFieldNameToUpperCase = (name) => {
    validateNameBinding(name);

    return {
      value: values[name].toUpperCase() || '',
      onChange: (e) => {
        const { value } = e.target;

        setValues(state => ({
          ...state,
          [name]: value,
        }));

        setErrors(state => ({
          ...state,
          [name]: validateField(name, value),
        }));

      },

    }
  }

  const bindingCalendarEvent = (name) => {
    validateNameBinding(name);

    return {

      value: values[name] || '',
      onChange: (e) => {

        if (!e) {
          return;
        }

        setValues(state => ({
          ...state,
          [name]: e,
        }));

        setErrors(state => ({
          ...state,
          // [name]: validateField(name, value),
        }));
      },

    }
  }


  const isValidForm = () => {
    const hasErrors = Object.keys(validations).some(name =>
      Boolean(validateField(name, values[name]))
    );
    return !hasErrors;
  }


  const reset = () => {
    setValues(initialState);
  }

  return {
    values,
    errors,
    validateField,
    isValidForm,
    bindingFieldName,
    bindingCalendarEvent,
    bindingFieldNameToUpperCase,
    bindingFielRadio,
    bindingFielCheck,
    reset
  };

}
