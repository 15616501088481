import { connect } from 'react-redux';
import React, { ReactElement } from 'react';
import { IntStore } from '../../schemas/store.interface';
import { useTranslation } from 'react-i18next';
import { environment } from '../../environments/environment';
import { getCompany, getUserLang } from '../../core/local-storage/local-storage.service';
import { AvailableCompanyAndCodes } from '../../utils/const'

const Footer = (props: any): ReactElement => {

  const { t } = useTranslation();
  const getCodeCompany = (nameCompany: string) => {

    const company = AvailableCompanyAndCodes.find(company => company.companyName === nameCompany);
  
    return company?.companyKey;
  }
  const companyStorage = getCompany();
  const companyCode = getCodeCompany(companyStorage);

  return (
    <footer className="footer">
      <div className="footer-content">
        <p>{t('footer_text')}</p>
        <div className="legal">
          <a href={`${environment.static.privacyPolicy}/Pol%C3%ADtica%20de%20Privacidad_${companyCode}.pdf`} target="_blank">{t(`PRIVACY_POL`)}</a>
          {/* <a href={`${environment.static.pdfs}/${companyStorage}/${getUserLang()}/CondicionesLegales_${companyCode}.pdf`} target="_blank">{t(`INFO_LEGAL`)}</a> */}
          {/* Botón de configuración de cookies de OneTrust - Comienzo */}
          <button className="ot-sdk-show-settings">{t(`CONFIG_COOKIES`)}</button>
          {/* Botón de configuración de cookies de OneTrust - Finalización */}
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = (state: IntStore) => {
  return {

  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
