import React, { ReactElement, useEffect, useState } from 'react';
// import styles from './selection-baggage.module.scss';
import { IntSelectionBaggageProps } from '../../../schemas/baggage.interface';
import { connect, useDispatch } from 'react-redux';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { IntStore } from '../../../schemas/store.interface';
import { useHistory } from 'react-router-dom';
import { setView } from '../../../core/masterdata/masterdata.reducer';
import { getCompany } from '../../../core/local-storage/local-storage.service';
import { getBaggages, postBaggages, cancelBaggage } from './selection-baggage.service';
import { showLoading } from '../../../components/ui/loading/loading.reducer';
import Carousel, {} from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { environment } from '../../../environments/environment';
import { getCodeCompany } from '../../user/user.service';
import { mock } from './selection-const'
import { useTranslation } from 'react-i18next';
const SelectionBaggage = (props: IntSelectionBaggageProps): ReactElement => {

  const history = useHistory();
  const { t } = useTranslation();
  const companyStorage = getCompany();
  const [arrBaggages, setArrBaggages]: Array<any> = useState([]);
  const [itemSelected, setItemSelected]: Array<any> = useState([]);

  const [urlRedirect, seturlRedirect] = useState('');
  const [showConfirmBaggage, setshowConfirmBaggage] = useState(false);

  const [openPopUp, setopenPopUp] = useState(false);
  const [urlImage, seturlImage] = useState('');

  useEffect(() => {
    async function load() {

      const payload = {
        Language: props.user.language.slice(3, 5),
        RecordNumber: props.user.recordNumber,
        LossAgentParentId: props.user.lossAgentParentId,
        Group: getCodeCompany(companyStorage),
        Country: props.baggage.countrySelected.toUpperCase(),
        Culture: props.user.language.slice(3, 5),
      }
      props.showLoading();
      const baggagesCarousel = await props.getBaggages(payload, history);

      if (!baggagesCarousel) {
        return;
      }

      const arrBaggages: Array<any> = baggagesCarousel.maletas.id_grupo;

      setArrBaggages(arrBaggages);
    }
    load();
  }, [])

  const validateSelection = (): Boolean => {
    let arrValidator: Array<any> = [];
    if (itemSelected.length !== arrBaggages.length) {
      return false;
    }

    itemSelected.forEach(item => {
      !item.hasOwnProperty('color') ? arrValidator.push(false) : arrValidator.push(true);
    });

    return arrValidator.every((item) => item === true);
  }

  const eventContinueSelectionBaggage = async () => {

    if (!validateSelection()) {
      console.log('Por favor, selecciona una maleta y un color');
      return
    }

    const suitcases = itemSelected.map(item => {
      return {
        colorCode: item.color,
        id: item.ref
      }
    })

    const payload = {
      lossAgentParentId: props.user.lossAgentParentId,
      suitcases: [
        ...suitcases
      ]
    }

    const response = await props.postBaggages(payload, history);

    if (!response.redirect.pageTo) {
      console.log('La respuesta back esta mal formada');
      return;
    }

    seturlRedirect(response.redirect.pageTo)
    setshowConfirmBaggage(true);
  };

  const continueBaggage = () => {
    history.push(`/${companyStorage}${urlRedirect}`);
  }

  const goBack = () => {
    history.goBack()
  }


  const getBackgroundColor = (color: string) => {

    return {
      backgroundColor: '#' + color,
    }
  }

  const getImagesStorageCarousel = (item) => {
    if (!item || !item.fotos || !item.fotos.foto || !item.fotos.foto[0] || !item.fotos.foto[0].fichero) {
      return environment.static.imagesMaletas + companyStorage + '/nomaleta.jpg';
    }
    return environment.static.imagesMaletas + companyStorage + '/' + item.fotos.foto[0].fichero;
  }

  const getImagesStorage = (idImagen: string) => {
    if (!idImagen) {
      return environment.static.imagesMaletas + companyStorage + '/nomaleta.jpg';
    }
    return environment.static.imagesMaletas + companyStorage + '/' + idImagen;
  }


  const selectItemCarousel = (e, item: any, indexCarousel: number) => {
    setItemSelected((state) => {
      let newState: Array<any> = [];
      newState = [...state];
      newState[indexCarousel] = item;
      return newState;
    });
  }

  const setColorSelected = (colorSelected, indexCarousel) => {

    setItemSelected((state) => {
      let newState: Array<any> = [];
      newState = [...state];
      newState[indexCarousel] = itemSelected[indexCarousel];
      newState[indexCarousel].color = colorSelected;
      return newState;
    });
  }


  const setCancelBaggage = async () => {
    const payload = {
      deliveryCountry: props.baggage.countrySelected.toUpperCase(),
      firm: getCodeCompany(companyStorage),
      pir: props.user.userForm.pir,
    }
    props.showLoading();
    const response = await props.cancelBaggage(payload, history);
    history.push(`/${companyStorage}${response.redirect.pageTo}`);
  }

  const showImage = (foto?:any) => {
    if(foto){
      seturlImage(foto.fichero);
    }

    setopenPopUp(!openPopUp);
  }

  return (
    <div className="card" data-testid="selectionbaggage">


      {openPopUp && (
        <div className="detail-img">
          <div className="close-modal" onClick={() => { showImage() }}>×</div>
          <img src={getImagesStorage(urlImage)} />
        </div>
      )}

      {!openPopUp && (
        <>
          {!showConfirmBaggage && (
            <>
              { arrBaggages && arrBaggages.length > 0 &&
                arrBaggages.map((baggage, index) => (
                  <div key={index + '_' + baggage.id}>

                    <h3 className="ea-title">{t(`${companyStorage}.baggage_data.maleta_datos`)} {(index + 1)} </h3>

                    <Carousel
                      arrows
                      centered
                      infinite={false}
                      // onChange = { addArrowClickHandler }
                      slidesPerPage={3}
                    >
                      {baggage.maleta && baggage.maleta.length
                        && baggage.maleta.map((item, ind) => (
                          <img style={{ width: '100%' }} key={ind + '_carousel_' + getImagesStorageCarousel(item)}
                            onClick={(e) => selectItemCarousel(e, item, index)}
                            src={getImagesStorageCarousel(item)} />
                        ))}
                    </Carousel>


                    {itemSelected[index] && (
                      <div key={index + '__itemSelected_' + baggage.id}>

                        {itemSelected[index].nombre && (
                          <div className="item-name">
                            {itemSelected[index].nombre}
                          </div>
                        )}

                        {itemSelected[index].descripcion && (
                          <div className="item-description">
                            {itemSelected[index].descripcion}
                          </div>
                        )}

                        <div className="images-size-box">

                          {itemSelected[index].fotos && itemSelected[index].fotos.foto && itemSelected[index].fotos.foto.map((foto, indexFoto) => (
                            <div key={index + '_' + indexFoto + '_images_zoom' + foto.fichero} className="images-size" onClick={() => { showImage(foto)}}>
                              <img src={getImagesStorage(foto.fichero)}/>
                            </div>
                          ))}
                        </div>

                        <h4>{t(`${companyStorage}.baggage-selection.colores`)}</h4>
                        <div className="color-box">
                          {itemSelected[index].colores && itemSelected[index].colores.color && itemSelected[index].colores.color.map((color, idx) => (
                            <div key={index + '_color_' + idx + color.colorcod}
                              className={(itemSelected[index].color === color.colorcod ? 'color-item active_color' : 'color-item no_active_color')}
                              onClick={() => setColorSelected(color.colorcod, index)}
                              style={getBackgroundColor(color.colorcod)}>
                              {color.nombre}
                              {color.colorcod}

                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {!itemSelected[index] && (
                      <h4>{t(`${companyStorage}.baggage-selection.title`)}</h4>
                    )}
                  </div>
                ))}


              <div className="btn-wrapper">
                <button data-qa="submit-pir" className="btn btn--primary" id="btn-user-continue" onClick={goBack}>
                  {t('DEFAULT_BTN_VOLVER')}
                </button>

                <button className="btn btn--primary" id="btn-selection-baggage" disabled={!validateSelection()} onClick={() => eventContinueSelectionBaggage()}>
                  {t('DEFAULT_BTN_SELECCIONAR')}
                </button>
              </div>
            </>
          )}

          {showConfirmBaggage && (
            <>
              {itemSelected && itemSelected.map((baggage, index) => (
                <div className="baggage-selected" key={index + '_baggageSelected'}>

                  <h5>{baggage.nombre}</h5>
                  <p>{baggage.descripcion}</p>
                  <div className="color-box">

                          {itemSelected[index].colores.color.map((color) => (
                            <div className={(itemSelected[index].color === color.colorcod ? 'color-box-item' : 'color-box-item no_active_color')}
                              key={index + '_color_' + color.colorcod}
                              style={getBackgroundColor(color.colorcod)}>

                              {baggage.colorcod}

                            </div>
                          ))}
                  </div>
                  <br />
                </div>
              ))}

              <div className="btn-wrapper">
                <button data-qa="submit-pir" className="btn btn--primary" id="btn-user-continue" onClick={() => { setCancelBaggage() }}>
                  {t(`DEFAULT_BTN_CANCELAR`)}
                </button>
                <button className="btn btn--primary" id="btn-selection-baggage" onClick={() => continueBaggage()}>
                  {t(`${companyStorage}.captura.continue`)}
                </button>
              </div>
            </>
          )}
        </>
      )}

    </div>
  );
};

const mapStateToProps = (state: IntStore) => {
  return {
    view: state.masterdata.view,
    user: state.user,
    baggage: state.baggage
  };
};

const mapDispatchToProps = {
  setView,
  showLoading,
  getBaggages,
  postBaggages,
  cancelBaggage
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectionBaggage);




