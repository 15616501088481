import axios from 'axios';
import { getToken } from '../rest/token.serice';
import { getByKey, setValue } from '../local-storage/local-storage.service';

export const initInterceptor = () => {
  const TOKEN_KEY: string = 'AccessToken';

  function setHeaders(config: any, token: string) {
    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    };
  }

  axios.interceptors.request.use(
    function (config: any) {
      const token: string = getByKey(TOKEN_KEY);
      if (token) setHeaders(config, token);
      return Promise.resolve(config);
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        let token: string = getByKey(TOKEN_KEY);
        if (!token) {
          return getToken()
            .then((response: any) => {
              setValue(TOKEN_KEY, response);
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + response;
              return Promise.resolve(axios(originalRequest));
            })
            .catch((err) => {
              delete axios.defaults.headers.common['Authorization'];
              return Promise.reject(err);
            });
        }
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        return Promise.resolve(axios(originalRequest));
      }
      return Promise.reject(error);
    }
  );
};
