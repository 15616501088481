import { IntUserReducer } from '../../../schemas/user.interface';
import { IntActionsGeneral } from '../../../schemas/actions.interface';

/* ==================================================================
  TYPES
================================================================== */
export const SET_RESUME_BAGGAGE = 'SET_RESUME_BAGGAGE';
export const SET_RESUME_DATA = 'SET_RESUME_DATA';
export const CLEAR_CONFIRM = 'CLEAR_CONFIRM';



/* ==================================================================
  ACTIONS
================================================================== */
export const setResumeBaggage = (payload:boolean): IntActionsGeneral => ({
  type: SET_RESUME_BAGGAGE,
  payload: payload,
});

export const setBaggageData = (payload:any): IntActionsGeneral => ({
    type: SET_RESUME_DATA,
    payload: payload,
});

export const clearConfirm = (): IntActionsGeneral => ({
  type: CLEAR_CONFIRM,
});
/* ==================================================================
  REDUCERS
================================================================== */
// InitialState
export const confirmInitialState: any = {
    resumeBagagge:false,
    confirmPageData:{}
};

// Reducer
export const confirm = (state = confirmInitialState, action: IntActionsGeneral): IntUserReducer => {
  switch (action.type) {
    case SET_RESUME_BAGGAGE:
      return {
        ...state,
        confirmBabageData: action.payload,
      };
    case SET_RESUME_DATA:
      return {
        ...state,
        confirmPageData:action.payload,
      };
    case CLEAR_CONFIRM:
        return {
            ...state,
            resumeBagagge:false,
            confirmPageData:{}
        }
    default:
      return state;
  }
};
