import { connect } from 'react-redux';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { IntStore } from '../../../schemas/store.interface';
import { IntLoadingProps } from '../../../schemas/loading.interface';

const Loading = (props: IntLoadingProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="loading" data-testid="loading">
      {props.loading?.show && (
        <div className="loading--content">
          <div>
            <h2>{t('app.loading')}</h2>
            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: IntStore) => {
  return {
    loading: state.loading,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
