const BASE_URL = process.env.REACT_APP_HOST_ENV === 'production'
  ? 'https://equipajes-api.europ.es'
  : 'https://equipajes-dev.europ20.es';

const STORAGE = process.env.REACT_APP_HOST_ENV === 'production'
  ? 'https://easppsta001equipajes.blob.core.windows.net'
  : 'https://easpdsta001equipajes.blob.core.windows.net';

const IDENTITY_SERVER = process.env.REACT_APP_HOST_ENV === 'production'
  ? 'https://identity.europ.es'
  : 'https://identity-dev.europ20.es';

const QUERY_PARAMS_IDENTITY = {
  client_id: 'equipajes-client.api',
  client_secret: 'Xdm6o--biSxY~wZXL4ip',
  grant_type: 'client_credentials',
};

export const environment = {
  reactAppEnv: process.env.REACT_APP_HOST_ENV,
  env: process.env.NODE_ENV,
  debug: process.env.NODE_ENV === 'production' ? false : true,
  identity: QUERY_PARAMS_IDENTITY,
  endpoints: {
    apiversion: `${BASE_URL}/api/version`,
    pirs:`${BASE_URL}/api/Pirs`,
    languages:`${BASE_URL}/api/Languages`,
    countries : `${BASE_URL}/api/Countries/`,
    records:`${BASE_URL}/api/Records`,
    Dprs:`${BASE_URL}/api/Dprs`,
    phones:`${BASE_URL}/api/Phones`,
    deliveries:`${BASE_URL}/api/Deliveries`,
    getCodePostalIslands:`${BASE_URL}/api/Deliveries/GetCodePostalIslands`,
    suitcases: `${BASE_URL}/api/Suitcases`,
    pirs_where_to_go:`${BASE_URL}/api/Pirs/where-to-go`,
    auth: `${BASE_URL}/api/AuthorizeTest`,
    configuration: `${BASE_URL}/api/Configuration`,
    getListYear:`${BASE_URL}/api/Suitcases/suitcase-age`,
    getListSize:`${BASE_URL}/api/Suitcases/suitcase-size`,
    stores:`${BASE_URL}/api/Stores`,
    getBaggagesCarousel:`${BASE_URL}/api/Xml`,
    getToken: `${IDENTITY_SERVER}/connect/token`
  },
  static:{
    translate: `${STORAGE}/locales/`,
    imagesMaletas : `${STORAGE}/assets/maletas/`,
    pdfs: `${STORAGE}/assets/pdfs`,
    privacyPolicy: `${STORAGE}/assets/pdfs/privacy_policy`
  },
  files: {
    maxSize: 20000000,
    fileTypesAllowed: ['doc', 'txt', 'csv', 'xml', 'zip'],
  },
};
