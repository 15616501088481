import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IntStore } from '../../../schemas/store.interface';
import { IntBaggageProps } from '../../../schemas/baggage.interface';
import { setView } from '../../../core/masterdata/masterdata.reducer';
import { getCompany } from '../../../core/local-storage/local-storage.service';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormArray } from '../../../hooks/use-formsArray';
import EaInput from '../../../components/form/ea-input/ea-input';
import EaSelect from '../../../components/form/ea-select/ea-select';
import { listSize, listAntiquity, validations } from './description.const'
import { showLoading } from '../../../components/ui/loading/loading.reducer';
import { postSuitcases, getPirs, getListSize, getListYear } from './description-baggage.service';
import { getCodeCompany } from '../../user/user.service';


const DescriptionBaggage = (props: IntBaggageProps): ReactElement => {

  const arrayBaggage = props.suitcases;
  const validat = validations;
  const companyStorage = getCompany();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [listYear, setlistYear] = useState([]);
  const [listSizes, setlistSizes] = useState([]);


  const {
    values,
    errorsArray,
    bindingFieldNameInArrayForm,
    isValidForm,
  } = useFormArray(validat, arrayBaggage);


  useEffect(() => {
    async function load() {
      dispatch(props.showLoading());
      const responseSize = await props.getListSize(history);
      const responseYear = await props.getListYear(history);

      const responseSizeMapped = responseSize.map((item) => {
        item = {
          value: item.value,
          name: item.label
        }
        return item
      });

      const responseYearMapped = responseYear.map((item) => {
        item = {
          value: item.value,
          name: item.label
        }
        return item
      });

      responseSizeMapped.unshift('-');
      responseYearMapped.unshift('-')

      setlistYear(responseYearMapped);
      setlistSizes(responseSizeMapped);
    }
    load();
  }, [])

  const mappedAndPostSuitcases = async (values) => {
    const suitcasesDto: Array<any> = [];
    values.map((item: any, index) => {
      let itemDTO = {
        idSuitcase: values[index].id,
        brand: values[index].marca,
        price: values[index].precio_estimado,
        age: values[index].antiguedad,
        size: values[index].tamanhio,
      }

      suitcasesDto.push(itemDTO);
    })

    const payload = {
      lossAgentParentId: props.user.lossAgentParentId,
      recordNumber: props.user.recordNumber,
      suitcasesSelectedDto: suitcasesDto
    };
    dispatch(showLoading());

    const resp = await props.postSuitcases(payload, history);
    return resp;
  }


  const eventContinueCountryBaggage = async () => {
    if (!isValidForm(arrayBaggage.length)) {
      return;
    }

    const postSuitcasesResponse = await mappedAndPostSuitcases(values);


    const payload = {
      LossAgentParentId: props.user.lossAgentParentId,
      IsRefund: props.user.isRefund,
      IsClientVIP: props.user.isVIPClient,
      DeliveryCountry: props.baggage.countrySelected,
      pir: props.user.userForm.pir,
      Firm: getCodeCompany(companyStorage)
    }

    await props.getPirs(payload, history);
  };

  const goBack = () => {
   if(props.isTest) return;
     history.goBack();
  }

  return (
    <div className="card" data-testid="countrybaggage">
      <div >
        <p>{t(`${companyStorage}.baggage_data.title`)}</p>
        <p className="sub-description">{t(`${companyStorage}.baggage_data.subtitle`)}</p>

        <form> 
          {arrayBaggage && arrayBaggage.map((baggage: any, index) => (
            <div key={index}>

              <h3 className="ea-title">{t(`${companyStorage}.baggage_data.maleta_datos`)} {(index + 1)} </h3>

              {baggage.color && baggage.color.length > 1 && (
                <label className="ea-text-label">{baggage.color} </label>
              )}
              {baggage.type && baggage.type.length > 1 && (
                <label className="ea-text-label">{baggage.type} </label>
              )}
              {baggage.desc1 && baggage.desc1.length > 1 && (
                <label className="ea-text-label">{baggage.desc1} </label>
              )}
              <div className="ea-input-box-two">
                <div className="ea-input">
                  <EaInput
                    label={t(`${companyStorage}.baggage_data.marcaLb`)}
                    title={t(`${companyStorage}.baggage_data.marcaT`)}
                    idInput={`marca-datos-maleta${index}`}
                    type="text"
                    bindingField={bindingFieldNameInArrayForm(baggage.id, index, 'marca')}
                    inputDataQa="marca-datos-maleta"
                    error={errorsArray && errorsArray[index] && errorsArray[index].hasOwnProperty('marca') ? errorsArray[index]['marca'] : null}
                    name="marca"
                  ></EaInput>
                </div>
                <div className="ea-input">
                  <EaInput
                    label={t(`${companyStorage}.baggage_data.precioLb`)}
                    title={t(`${companyStorage}.baggage_data.precioT`)}
                    idInput={`precio-estimado-maleta${index}`}
                    type="number"
                    bindingField={bindingFieldNameInArrayForm(baggage.id, index, 'precio_estimado')}
                    inputDataQa="precio-estimado-datos-maleta"
                    error={errorsArray && errorsArray[index] && errorsArray[index].hasOwnProperty('precio_estimado') ? errorsArray[index]['precio_estimado'] : null}
                    name="marca"
                  ></EaInput>
                </div>
              </div>
              <div className="ea-input-box-two">
                <div className="ea-input">
                  <EaSelect
                    idInput={"antiguedad-datos-maleta"}
                    label={t(`${companyStorage}.baggage_data.antiguedadLb`)}
                    title={t(`${companyStorage}.baggage_data.antiguedadT`)}
                    name="tarjeta-iberia-plus"
                    bindingField={bindingFieldNameInArrayForm(baggage.id, index, 'antiguedad')}
                    listItems={listYear.length > 0 ? listYear : []}
                    error={errorsArray && errorsArray[index] && errorsArray[index].hasOwnProperty('antiguedad') ? errorsArray[index]['antiguedad'] : null}
                    inputDataQa="user-pir-select-tarjeta"
                  ></EaSelect>
                </div>

                <div className="ea-input">
                  <EaSelect
                    idInput={"tamanhio"}
                    label={t(`${companyStorage}.baggage_data.tamanhioLb`)}
                    title={t(`${companyStorage}.baggage_data.tamanhioT`)}
                    name="tarjeta-iberia-plus"
                    bindingField={bindingFieldNameInArrayForm(baggage.id, index, 'tamanhio')}
                    listItems={listSizes.length > 0 ? listSizes : []}
                    error={errorsArray && errorsArray[index] && errorsArray[index].hasOwnProperty('tamanhio') ? errorsArray[index]['tamanhio'] : null}
                    inputDataQa="user-pir-select-tarjeta"
                  ></EaSelect>
                </div>
              </div>
            </div>
          ))}
        </form>
        <div className="btn-wrapper">
          <button data-qa="submit-pir" className="btn btn--primary" id="btn-user-continue" onClick={goBack}>
            {t('DEFAULT_BTN_VOLVER')}
          </button>
          <button className="btn btn--primary" disabled={!isValidForm(arrayBaggage.length)} id="button-country-select" onClick={() => eventContinueCountryBaggage()}>
            {t(`${companyStorage}.captura.continue`)}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IntStore) => {

  return {
    view: state.masterdata.view,
    suitcases: state.baggage.suitcases,
    user: state.user,
    baggage: state.baggage
  };


};

const mapDispatchToProps = {
  setView,
  postSuitcases,
  getPirs,
  getListSize,
  showLoading,
  getListYear
};

export default connect(mapStateToProps, mapDispatchToProps)(DescriptionBaggage);
