import axios from 'axios';
import { environment } from '../../environments/environment';
import { showError } from '../error/error.reducer';
import { getCompany } from '../../core/local-storage/local-storage.service';
import { hideLoading } from '../../components/ui/loading/loading.reducer';



export const getStoreList = (payload,useHistory) => {
    const companyStorage = getCompany();
    const url  = environment.endpoints.stores + '/' + payload;
    return (dispatch: any, getState: any) => {
      return axios
        .get(url)
        .then((rawdata:any) => {
            return rawdata.data;
        })
        .catch((error) => {
          dispatch(showError({ msg: 'ERROR: USER_SERVICE', redirect: `${companyStorage}/choose-store`, codeError: 'ERR000001' }));
          useHistory.push(`/${companyStorage}/error`);
        }).finally(()=>{
            dispatch(hideLoading());
        })
    };
  };
  
  
  export const getStoreInformation = (lossAgentParentId,borrowerAssistanceId,useHistory) => {
    const companyStorage = getCompany();

    const url = environment.endpoints.stores + '/' + lossAgentParentId + '/' + borrowerAssistanceId;
  
    return (dispatch: any) => {
      return axios
        .get(url)
        .then((response) => {
          if(!response || !response.data ){
            console.log('GET_LIST_COUNTRIES :NO RESPONDIO CON LA ESTRUCTURA ESPERADA ')
            return;
          }

          return response.data[0];
        })
        .catch((error) => {
          dispatch(showError({ msg: 'ERROR: USER_SERVICE', redirect: `${companyStorage}/choose-store`, codeError: 'ERR000001' }));
          useHistory.push(`/${companyStorage}/error`);
        }).finally(()=>{
            dispatch(hideLoading())
        })
    };
  };




  
export const getCountry = (payload,useHistory) => {
  const companyStorage = getCompany();
  const url  = environment.endpoints.countries + payload.lossAgentParentId + '/' + payload.language;
  return (dispatch: any, getState: any) => {
    return axios
      .get(url)
      .then((rawdata:any) => {
          return rawdata.data;
      })
      .catch((error) => {
        dispatch(showError({ msg: 'ERROR: USER_SERVICE', redirect: `${companyStorage}/choose-store`, codeError: 'ERR000001' }));
        useHistory.push(`/${companyStorage}/error`);
      }).finally(()=>{
          dispatch(hideLoading());
      })
  };
};


  export const getDpr = (payload,useHistory) => {
    const companyStorage = getCompany();
    const url  = environment.endpoints.Dprs ;
    const params = new URLSearchParams(payload);
    return (dispatch: any, getState: any) => {
      return axios
        .get(url , {params} )
        .then((rawdata:any) => {

            return rawdata.data;
        })
        .catch((error) => {
          dispatch(showError({ msg: 'ERROR: LANGUAGE_SERVICE', redirect: `${companyStorage}/choose-store`, codeError: 'ERR000001' }));
          useHistory.push(`/${companyStorage}/error`);
        }).finally(()=>{
            dispatch(hideLoading());
        })
    };
  };
  



 export const postStores = (payload, useHistory) => {
     const companyStorage = getCompany();

     const url = environment.endpoints.stores +  '/' + payload.lossAgentParentId + '/' + payload.pir;
     return (dispatch: any) => {
         return axios
             .post(url , {...payload.data})
             .then((response) => {
                 if(!response || !response.data || !response.data.data){
                     console.log('postStores:NO RESPONDIO CON LA ESTRUCTURA ESPERADA ');
                     return;
                 }
 
                 console.log('Response ListBaggages: ', response);
                 return response.data.data;
             })
             .catch((error) => {
                 dispatch(showError({ msg: 'ERROR: LANGUAGE_SERVICE', redirect: `${companyStorage}/choose-store`, codeError: 'ERR000001' }));
                 useHistory.push(`/${companyStorage}/error`);
             }).finally(() => {
                 dispatch(hideLoading());
             })
     };
 };