export const getUserLang = () => {
  const lang = localStorage.getItem('i18nextLng');
  return lang ? lang : '';
};

export const setUserLang = (lang: string): void => {
  localStorage.setItem('i18nextLng', lang);
};

export const getCompany = () => {
  const comp = localStorage.getItem('ea-company');
  return comp ? comp : '';
};

export const setCompany = (comp: string): void => {
  localStorage.setItem('ea-company', comp);
};

export const getListCodePostal = () => {
  const comp = localStorage.getItem('ListCodePostal');
  return comp ? comp : '';
};

export const setListCodePostal = (comp: string): void => {
  localStorage.setItem('ListCodePostal', comp);
};

export const getByKey = (key: string) => {
  const comp = localStorage.getItem(key);
  return comp ? comp : '';
};


export const deleteByKey = (key: string) => {
  localStorage.removeItem(key);
};


export const setValue = (key: string, value: any): void => {
  localStorage.setItem(key, value);
};
