import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IntStore } from '../../../schemas/store.interface';
import { connect, useDispatch } from 'react-redux';
import { listHeader } from './main-header.const';
import { getCompany } from '../../../core/local-storage/local-storage.service';
import { useLocation } from 'react-router';
import { setStep } from './main-header.reducer';
import LanguageList from '../../../views/language-list/language-list';
import { setUserLanguage } from '../../../views/user/user.reducer';
import { setView } from '../../../core/masterdata/masterdata.reducer';
import { hideLoading, showLoading } from '../../ui/loading/loading.reducer';
import { getLanguageList } from '../../../views/language-list/language-list.service';

const MainHeader = (props: any): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [hasError, sethasError] = useState(false);

  const companyStorage = getCompany();
  const list = listHeader(companyStorage);


  const location = useLocation();

  // Efecto para controlar si en el navegador directamente nos hacen back
  useEffect(() => {
    const itemUrl = list.find((item:any) =>{
      if(typeof(item.path) === 'string'){
        return item.path === location.pathname
      }

      return item.path.find(path=> path === location.pathname);

    });
    let indexItem = itemUrl && itemUrl.numberStep ? itemUrl.numberStep : 0;

    //SI ESTAMOS EN CHOOSE-STORE Y ESTAMOS EN CONFIRM , AUTOMATICAMENTE PONEMOS EL STEP FINAL
    // PARA HACERLO POR URL NECESITAMOS DIVIDIR CHOOSE-STORE EN DOS PANTALLAS

    if(location.pathname === `${companyStorage}/choose-store` &&
      props.baggage.isStoreSelected){
      indexItem = 4;
    }


    dispatch(setStep({ step: indexItem }));
  }, [location.pathname]);

  //comprobamos si hay error para ocultar los steps
  useEffect(() => {
    sethasError(props.error.codeError ? true : false);
  }, [props.error])


  return (
    <div className="header" data-testid="mainheader">
      <header className="header--top">
        <div className="container">
        <div className="logo"></div>
        <LanguageList
          {...setUserLanguage}
          {...setView}
          {...hideLoading}
          {...showLoading}
          {...getLanguageList}
          />
        </div>
      </header>
      <h2 className="header--title">{t(`${companyStorage}.header.title`)}</h2>
      {!hasError && (
        <ul className="wizard">
          {list && list.map((itemHeader) => (
            <li key={itemHeader.key} className={itemHeader.numberStep <= props.header.step ? 'actived' : ''}>
              <span>{itemHeader.numberStep}
                <span className="wizard--name">{t(itemHeader.nameStep)}</span>
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const mapStateToProps = (state: IntStore) => {
  return {
    error: state.error,
    header: state.header,
    user:state.user,
    baggage:state.baggage
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);
