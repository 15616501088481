import { userForm } from '../../schemas/userForm.interface'
import { AvailableValidations } from '../../hooks/use-forms';

  export const initialFormIberia: userForm = {
    pir: 'VLCIB39600',
    fechaIncidencia: new Date('12/10/2020'),
    apellido: 'VELASCO',
    tarjetaIberiaPlus : '',
    tarjetaIberia: '',
    numberOfRetris:0
  }

  export const validationsFormIBeria = (company:string) => {
    return {
      pir: {
        type: AvailableValidations.PATTERN,
        value: /^[a-zA-Z]{5}[\d]{5}$/,
        message: `${company}.captura.revPirError`,
      },
      fechaIncidencia: {
        type: '',
        value: '',
        message: `${company}.captura.rfvFechaPirError`,
      },
      apellido: {
        type: AvailableValidations.PATTERN,
        value: /^[a-zA-Z\s]{2,30}$/,
        message: `${company}.captura.rfvNombreLengthError`,
      },
      tarjetaIberiaPlus: {
        type:'',
        value: '',
        message: `${company}.captura.defaultErrorRequired`,
      },
      tarjetaIberia: {
        type: '',
        value: '',
        message: `${company}.captura.defaultErrorRequired`,
      },
    }
  }


  export const initialFormIVueling: userForm = {
    pir: 'SDRVY11685',
    fechaIncidencia: new Date('16/10/2020'),
    apellido: 'RODRIGUEZDELVAL',
    numberOfRetris:0,
  }

  export const validationsFormVueling = (company:string) => {
    return {
      pir: {
        type: AvailableValidations.PATTERN,
        value: /^[a-zA-Z]{5}[\d]{5}$/,
        message: `${company}.captura.revPirError`,
      },
      fechaIncidencia: {
        type: '',
        value: '',
        message: `${company}.captura.rfvFechaPirError`,
      },
      apellido: {
        type: AvailableValidations.PATTERN,
        value: /^[a-zA-Z\s]{2,30}$/,
        message: `${company}.captura.rfvNombreLengthError`,
      }
    }
  }

  export const listaTarjetasIberiaPlus = [
    {
      name:'iberia.captura.ListItem6Text',
      value: 'N'
    },
    {
      name:'iberia.captura.ListItem4Text',
      value: 'PL'
    },
    {
      name:'iberia.captura.ListItem3Text',
      value: 'PO'
    },
    {
      name:'iberia.captura.ListItem2Text',
      value: 'PP'
    },
    {
      name:'iberia.captura.ListItem5Text',
      value: 'PC'
    },
    {
      name:'iberia.captura.ListItem1Text',
      value: 'CS'
    },
  ]



export const setOldValuesPir =  (userFormStore:userForm) =>{
    return {
      pir: userFormStore.pir ? userFormStore.pir : '',
      fechaIncidencia: userFormStore.fechaIncidencia ? userFormStore.fechaIncidencia : new Date(),
      apellido: userFormStore.apellido ? userFormStore.apellido : '',
      tarjetaIberiaPlus: userFormStore.tarjetaIberiaPlus ? userFormStore.tarjetaIberiaPlus : '',
      tarjetaIberia: userFormStore.tarjetaIberia ? userFormStore.tarjetaIberia : '',
      numberOfRetris: userFormStore.numberOfRetris ? userFormStore.numberOfRetris : 0
    }
}
