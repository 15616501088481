/****************************************************
 * -------------Constantes de aplicacion-------------
 **************************************************/

import es from  '../assets/images/es.svg'
import de from  '../assets/images/de.svg'
import en from  '../assets/images/en.svg'
import fr from  '../assets/images/fr.svg'
import it from  '../assets/images/it.svg'
import pt from  '../assets/images/pt.svg'


/**
 * Constante en la que almacenaremos las compañias de aceso a la web y sus codigos
 * se utilizan por ejemplo en la pagina pir , para identificar el companyKey que tenemos que enviar 
 * a back tamien para las validaciones he inicializaiones del formulario.
 */

export const AvailableCompanyAndCodes = [
  {
    companyName: 'iberia',
    companyKey:'IBE',
  },
  {
    companyName: 'vueling',
    companyKey:'VLG',
  }
]




export const getImages  = (lang:string) =>{
  switch (lang) {
    case 'es-ES':
      return es
    case 'de-DE':
      return de
    case 'en-US':
      return en
    case 'fr-FR':
      return fr
    case 'it-IT':
      return it
    case 'pt-PT':
      return pt
    default:
      break;
  }
}



