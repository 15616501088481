import { AvailableValidations } from "../../../hooks/use-forms"

export const listSize = [
    {
        id: '1',
        name: '10cm',
        value: 10,
    },
    {
        id: '2',
        name: '20cm',
        value:20,
    },
    {
        id: '3',
        name: '30cm',
        value:30,
    },
    {
        id: '4',
        name: '40cm',
        value:40,
    },
    {
        id: '5',
        name: '50cm',
        value:50,
    },
    {
        id: '6',
        name: '60cm',
        value:60,
    },
    {
        id: '7',
        name: '70cm',
        value:70,
    },
    {
        id: '8',
        name: '80cm',
        value:80,
    },
    {
        id: '9',
        name: '90cm',
        value:90,
    },
    {
        id: '10',
        name: '100cm',
        value:100,
    },
    {
        id: '110',
        name: '110cm',
        value:110,
    },
]

export const listAntiquity = [
    {
        id: '1year',
        name: '1 - year',
        value:1,
    },
    {
        id: '2year',
        name: '2 - year',
        value:2,
    },
    {
        id: '3year',
        name: '3 - year',
        value:3,
    },
]

export const mockBaggages = [
    {
        id: 'maleta_1',
        color: 'ROJO /LIMA',
        forma: 'BLANDA',
        descripcion: 'DESCRIPCION 1',
        marca: '',
        precio_estimado: null,
        antiguedad: '2',
        tamanhio: '10',
    },
    {
        id: 'maleta_2',
        color: 'AZUL / VERDE',
        forma: 'RIGIDA',
        descripcion: 'DESCRIPCION 2',
        marca: '',
        precio_estimado: null,
        antiguedad: '1',
        tamanhio: '10',
    }
]


export const validations = {
    marca: {
        type: AvailableValidations.REQUIRED,
        value:'',
        message: `DEFAULT_ERROR_REQUIRED`,
    },
    precio_estimado: {
        type: AvailableValidations.REQUIRED,
        value:'',
        message: `DEFAULT_ERROR_REQUIRED`,
    },
    antiguedad: {
        type: AvailableValidations.REQUIRED,
        value:'',
        message: `DEFAULT_ERROR_REQUIRED`,
    },
    tamanhio: {
        type: AvailableValidations.REQUIRED,
        value:'',
        message: `DEFAULT_ERROR_REQUIRED`,
    },
}
