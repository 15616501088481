import axios from 'axios';
import { hideLoading } from '../../../components/ui/loading/loading.reducer';
import { getCompany } from '../../../core/local-storage/local-storage.service';
import { environment } from '../../../environments/environment';
import { parseRawdataDescription } from '../../../utils/parse.default.response';
import { showError } from '../../error/error.reducer';

export const getListCountries = (useHistory) => {
  const companyStorage = getCompany();
  return (dispatch: any) => {
    return axios
      .get(environment.endpoints.languages) // environment.endpoints.listCountries
      .then((response) => {

        return response
      })
      .catch((error) => {
        dispatch(showError({ msg: 'ERROR: LANGUAGE_SERVICE', redirect: `${companyStorage}/`, codeError: 'ERR000001' }));
        useHistory.push(`/${companyStorage}/error`);
      }).finally(()=>{
        dispatch(hideLoading());
    })
  };
};

export const getSuitcases = (payload , useHistory) => {

  const params = new URLSearchParams(payload);

  const companyStorage = getCompany();
  return (dispatch: any) => {
    return axios
      .get(environment.endpoints.suitcases , {params}) // environment.endpoints.listCountries
      .then((response) => {
        if(!response || !response.data ){
          console.log('GET_SUITCASES:NO RESPONDIO CON LA ESTRUCTURA ESPERADA ')
          return;
        }
        return response.data
      })
      .catch((error) => {
        dispatch(showError({ msg: 'ERROR: LANGUAGE_SERVICE', redirect: `${companyStorage}/`, codeError: 'ERR000001' }));
        useHistory.push(`/${companyStorage}/error`);
      }).finally(()=>{
        dispatch(hideLoading());
    })
  };
};


export const postSuitcases = (payload ,useHistory) => {

  const companyStorage = getCompany();
  return (dispatch: any) => {
    return axios
      .post(environment.endpoints.suitcases , {...payload})
      .then((response) => {
        if(!response || !response.data ){
          console.log('POST_SUITCASES:NO RESPONDIO CON LA ESTRUCTURA ESPERADA ')
          return;
        }
        return response
      })
      .catch((error) => {
        dispatch(showError({ msg: 'ERROR: LANGUAGE_SERVICE', redirect: `${companyStorage}/`, codeError: 'ERR000001' }));
        useHistory.push(`/${companyStorage}/error`);
      }).finally(()=>{
          dispatch(hideLoading());
      })
  };
};



export const getPirs = (payload , useHistory) => {
  const companyStorage = getCompany();

  const params = new URLSearchParams(payload);

  return (dispatch: any) => {
    return axios
      .get(environment.endpoints.pirs_where_to_go , {params})
      .then((response) => {

        if(!response || !response.data ){
          console.log('getPirs where_to_go:NO RESPONDIO CON LA ESTRUCTURA ESPERADA ')
          return;
        }
        parseRawdataDescription(response.data, dispatch, useHistory);
        return;
      })
      .catch((error) => {
        dispatch(showError({ msg: 'ERROR: LANGUAGE_SERVICE', redirect: `${companyStorage}/`, codeError: 'ERR000001' }));
        useHistory.push(`/${companyStorage}/error`);
      }).finally(()=>{
        dispatch(hideLoading());
    })
  };
};


export const getListSize = ( useHistory) => {
  const companyStorage = getCompany();


  return (dispatch: any) => {
    return axios
      .get(environment.endpoints.getListSize)
      .then((response) => {
        if(!response || !response.data ){
          console.log('getPirs where_to_go:NO RESPONDIO CON LA ESTRUCTURA ESPERADA ')
          return;
        }
        return response.data;
      })
      .catch((error) => {
        dispatch(showError({ msg: 'ERROR: LANGUAGE_SERVICE', redirect: `${companyStorage}/pir`, codeError: 'ERR000001' }));
        useHistory.push(`/${companyStorage}/error`);
      }).finally(()=>{
        dispatch(hideLoading());
    })
  };
};


export const getListYear = (useHistory) => {
  const companyStorage = getCompany();

  return (dispatch: any) => {
    return axios
      .get(environment.endpoints.getListYear)
      .then((response) => {
        if(!response || !response.data ){
          console.log('getPirs where_to_go:NO RESPONDIO CON LA ESTRUCTURA ESPERADA ')
          return;
        }
        return response.data;
      })
      .catch((error) => {
        dispatch(showError({ msg: 'ERROR: LANGUAGE_SERVICE', redirect: `${companyStorage}/pir`, codeError: 'ERR000001' }));
        useHistory.push(`/${companyStorage}/error`);
      }).finally(()=>{
        dispatch(hideLoading());
    })
  };
};

