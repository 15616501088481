import { connect } from 'react-redux';
import React, { ReactElement, Suspense } from 'react';
import { IntStore } from '../../schemas/store.interface';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getCompany } from '../../core/local-storage/local-storage.service';

const ContactUs = (props: any): ReactElement => {

  const { t } = useTranslation();
  const companyStorage = getCompany();

  const history = useHistory();
  const goBack = () => {
    history.goBack()
  }
  return (
    <Suspense fallback="">
    <div className="card">
      <p>{t(`${companyStorage}.contact-us.title`)}</p>

      <div className="btn-wrapper">
            <button data-qa="submit-pir" className="btn btn--primary" id="btn-user-continue" onClick={goBack}>{t('DEFAULT_BTN_VOLVER')}</button>
      </div>
    </div>
  </Suspense>
  );
};

const mapStateToProps = (state: IntStore) => {
  return {
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
