import axios from 'axios';
import { environment } from '../../environments/environment';
import { showError } from '../error/error.reducer';
import { getCompany } from '../../core/local-storage/local-storage.service';
import { hideLoading } from '../../components/ui/loading/loading.reducer';



export const getLanguageList = (useHistory) => {
    const companyStorage = getCompany();
    return (dispatch: any, getState: any) => {
      return axios
        .get(environment.endpoints.languages)
        .then((rawdata:any) => {
            return rawdata.data;
        })
        .catch((error) => {
          dispatch(showError({ msg: 'ERROR: LANGUAGE_SERVICE', redirect: `${companyStorage}/pir`, codeError: 'ERR000001' }));
          useHistory.push(`/${companyStorage}/error`);
        }).finally(()=>{
          dispatch(hideLoading())
        })
    };
  };
  
  