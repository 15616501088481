import axios from 'axios';
import { environment } from '../../environments/environment';
import { showError } from '../error/error.reducer';
import { getCompany } from '../../core/local-storage/local-storage.service';
import { DefaultResponse } from '../../schemas/defaultResponse.interface'
import { parseRawdata } from '../../utils/parse.default.response';
import { hideLoading } from '../../components/ui/loading/loading.reducer';
import { AvailableCompanyAndCodes } from '../../utils/const'
import { setRetris } from './user.reducer';
// const usersData = require('../../assets/mocks/user/users.json');


export const getApiVersion = () => {
  return (dispatch: any, getState: any) => {
    return axios
      .get(environment.endpoints.apiversion)
      .then((response) => {
        console.log('Response apiVersion: ', response);
      })
      .catch((error) => {
        console.log('Error apiVersion: ', error);

        dispatch(showError({ msg: 'Error en la llamada apiVersion', redirect: `${getCompany()}/error` }));
      });
  };
};

/**
 *  Parseo de errores y redirecciones
 *
 *  si tenemos error (response?.data?.error?.isError) redireccionamos a la pagina error.
 *
 *  todos los errores tienen un (response?.data?.error?.errorCode)
 *
 *  mapeamos las urls , dependiendo de la redireccion a saber:
 *
 *      SUCCESS
 *  ------------>
 *
 *    'datos prueba ----->   FICIB11112    'DPR VALIDO --> SUCCESSS AUMENTAMOS.
 *    'datos prueba ----->   FICIB11113    'baggage/delivery --> ok  ---> DEVUELVE NUMERO DE EXPEDIENTE.
 *    'datos prueba ----->   FICIB11114    'baggage/confirm --> ok  --> TIENDA ELEGIDA POR CLIENTE
 *
 *
 * ------------->
 *      KO
 * ------------->
 *
 *
 *    'datos prueba ----->        FICIB11116    'numIntentos ' -->  error numMax redirect to languges [A superado el maximo de intentos] 'ERR000004'
 *    'datos prueba ----->        FICIB11115    'dpr no valido --> error pir -> redireccionamos a pir 'contacte con nosotros'            'ERR000003'
 *    'datos prueba ----->        AAAAA11111    'pir erroneo   --> error pir -> redireccionamos a pir  vueling iberia                    'ERR000002'
 *    'datos prueba ----->        FICVY11117    'VUELING'      ---> EXPEDIDO   7dias                                                     'ERR000005'
 *    'datos prueba ----->        FICIB11118    'IBERIA'       ---> EXPEDIDO   [No tiene expired].
 *    'No existe el dato ---->   ['FICIB11119'] 'bbdd error    --> redireccionamos a pir                                                 'ERR000006'
 *    'datos prueba ----->        FICIB11118    'Cualquier error generico ' Ops algo fue mal                                             'ERR000001'
 *
 *
  En caso de error siempre se redirecciona a la pagina de error informamos al usuario y desde el
  boton volver redireccionamos a la pagina correspondiente , en caso ok seteamos los valores al store
  pertinente de cada pagina para tener los datos que nos devuelve el back accesibles.

  al final de cada llamada a back ocultaremos siempre el loading.
*/
export const comprobarPir = (payload: any, useHistory: any) => {

  const companyStorage = getCompany();
  const params = new URLSearchParams(payload);

  return (dispatch: any, getState: any) => {
    return axios
      .get(environment.endpoints.pirs, { params })
      .then((rawdata: DefaultResponse) => {

        if (Object.keys(rawdata.data.redirect).length === 0) {
          dispatch(setRetris());
          useHistory.push(`/${companyStorage}/pir`);
          return rawdata.data;
        }

        let data = parseRawdata(rawdata.data, dispatch , payload);

        if (data && data.error) {
          dispatch(showError({ msg: 'ERR : error en el servicio pir', redirect: `${companyStorage}/pir`, codeError: data.errorCode }));
          useHistory.push(`/${companyStorage}/error`);
          return;
        }

        useHistory.push(`/${companyStorage}${data}`);


      })
      .catch((error) => {
        dispatch(showError({ msg: 'ERROR: USER_SERVICE', redirect: `${companyStorage}/pir`, codeError: 'ERR000001' }));
        useHistory.push(`/${companyStorage}/error`);
      }).finally(() => {
        dispatch(hideLoading());
      });
  };
};


/***
 * METODOS AUXILIARES DEL COMPONENTE [MAPEADORES O FUNCIONES]
 */


export const formatDate = (date: any): string => {


  if (typeof (date) === 'string') {
    debugger
    return date;
  }
  let formatDate = '';

  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();


  if (mm < 10) { mm = '0' + mm; }

  if (dd < 10) { dd = '0' + dd; }

  formatDate = `${yyyy}-${mm}-${dd}`;

  return formatDate;
}


export const getCodeCompany = (nameCompany: string) => {

  const company = AvailableCompanyAndCodes.find(company => company.companyName === nameCompany);

  return company?.companyKey;
}





