import Axios, { AxiosRequestConfig } from 'axios';
import { environment  } from '../../environments/environment';

export const getToken = () => {
  return new Promise((resolve, reject) => {
    Axios(config)
      .then((response) => {
        resolve(response.data.access_token);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const queryParams = (params: any = {}) => {
  return Object.keys(params)
    .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&');
};

const data = queryParams({
  client_id: environment.identity.client_id,
  client_secret:  environment.identity.client_secret,
  grant_type:  environment.identity.grant_type,
});

const config: AxiosRequestConfig = {
  method: 'post',
  url: environment.endpoints.getToken,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  data: data,
};
