import React, { ReactElement, useState, useEffect } from 'react';
import styles from './baggage.module.scss';
import { useHistory } from 'react-router-dom';
import { IntStore } from '../../schemas/store.interface';
import { IntBaggageProps } from '../../schemas/baggage.interface';
import { setView } from '../../core/masterdata/masterdata.reducer';
import { getCompany } from '../../core/local-storage/local-storage.service';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { showLoading } from '../../components/ui/loading/loading.reducer';
import { getListCountries, getRecords, getDprs } from './baggage.service';
import { setCountrySelect, setSuitcases } from './baggabe.reducer';
import { getCodeCompany } from '../user/user.service';
import { setlossAgentParentIdData, setIsRefud } from '../user/user.reducer';
import { getSuitcases } from './description-baggage/description-baggage.service'
const Baggage = (props: IntBaggageProps): ReactElement => {

  const dispatch = useDispatch()
  const [countrySelected] = useState();
  const [listCountries, setListCountries] = useState([]);
  const [apellido, setApellido] = useState('');
  const [enable, setEnable] = useState(false);

  const companyStorage = getCompany();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    async function load() {
      props.showLoading();
      const countries = await props.getListCountries({ ...history });
      const apellido = await props.getRecords(props.user.recordNumber);
      setApellido(apellido.code);
      countries.unshift('-');
      countries.push({
          code: 'RM',
          name: `${t('DEFAULT_VALUE_REMBOLSO')}`
      });
      setListCountries(countries);
    }
    load();
  }, []);



  const getSwitCases = async () => {

    const payload = {
      recordNumber: props.user.recordNumber,
      language: props.user.language.slice(3, 5),
    }

    const responseSuitcases = await props.getSuitcases(payload, history);

    responseSuitcases.map((item: any) => {
      item.marca = item.bi;
      item.precio_estimado = null;
      item.tamanhio = '';
      item.antiguedad = '';
      item.marca = '';
    })

    dispatch(setSuitcases(responseSuitcases));
  }

  const goBack = () =>{
    history.goBack()
  }


  const eventCountrySelected = async (e: any) => {
    e.preventDefault();

    if (!e.target.value) {
      return;
    }

    dispatch(setCountrySelect(e.target.value));

    let payload = {
      lossAgentParentId: props.user.lossAgentParentId, //707 --> dato de prueba
      RecordNumber: props.user.recordNumber, // E07B1199154
      WorkField: e.target.value,// Pais selecionado 'es'
      Origin: getCodeCompany(companyStorage),// props.user.language  IBE-VLG
      AgencyPolicy: props.user.language//idioma storage 'es-ES'
    }

    dispatch(showLoading());
    const response = await props.getDprs(payload, history);

    if (!response) {
      return;
    }


    await getSwitCases();
    dispatch(setIsRefud({ isRefund: response?.isRefund  ? 1 : 0 }));
    dispatch(setlossAgentParentIdData({ lossAgentParentId: response?.lossAgentParentId }));

    setEnable(true);

    // history.push('/' + getCompany() + '/baggage/description');
  };

  const continueDesBaggage = () =>{
    history.push('/' + getCompany() + '/baggage/description');
  };



  return (
    <div className="card" data-testid="countrybaggage">
      {!countrySelected && (
        <div className={styles.baggage}>
          <h4>{t(`${companyStorage}.baggage-country.user`)} {apellido} {t(`${companyStorage}.baggage-country.title`)}</h4>
          <div className="ea-input">
            <label>{t(`${companyStorage}.baggage-country.subtitle`)}</label>
            <select id="country-select" onChange={(e) => eventCountrySelected(e)}>
              {listCountries && listCountries.length &&
                listCountries.map((country: any, index) => (
                  <option key={country.code + '-' + index} value={country.code}>
                    {country.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
      )}

          <div className="btn-wrapper">
                <button data-qa="submit-pir" className="btn btn--primary" id="btn-user-continue" onClick={goBack}>
                  {t('DEFAULT_BTN_VOLVER')}
                </button>

                <button className="btn btn--primary"  id="button-country-select" disabled={!enable} onClick={() => continueDesBaggage()}>
                  {t(`${companyStorage}.captura.continue`)}
                </button>

          </div>
    </div>
  );
};

const mapStateToProps = (state: IntStore) => {
  return {
    view: state.masterdata.view,
    user: state.user
  };
};

const mapDispatchToProps = {
  setView,
  showLoading,
  getListCountries,
  getRecords,
  getDprs,
  getSuitcases
};

export default connect(mapStateToProps, mapDispatchToProps)(Baggage);



// http://dev.equipajes.europ20.es/api/Dprs?lossAgentParentId=0&RecordNumebr=555&Origin=ES&AgencyPolicy=IBE
