import { IntUserReducer } from '../../schemas/user.interface';
import { IntActionsGeneral } from '../../schemas/actions.interface';

/* ==================================================================
  TYPES
================================================================== */
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_FORM_CAPTURE_PIR = 'SET_FORM_CAPTURE_PIR';
export const SET_RETRIES = 'SET_RETRIES';
export const CLEAR_FORM_CAPTURE_PIR = 'CLEAR_FORM_CAPTURE_PIR';
export const SET_BAGGAGE_DATA = 'SET_BAGGAGE_DATA';
export const SET_ID_LOSS_AGENT_PARENT_DATA = 'SET_ID_LOSS_AGENT_PARENT_DATA';
export const SET_IS_REFUND = 'SET_IS_REFUND';
export const SET_REASON = 'SET_REASON';
export const SET_CALCULATE_CATEGORY = 'SET_CALCULATE_CATEGORY';


/* ==================================================================
  ACTIONS
================================================================== */
export const setUserLanguage = (payload: string): IntActionsGeneral => ({
  type: SET_LANGUAGE,
  payload: payload,
});


export const setlossAgentParentIdData = (payload:any): IntActionsGeneral => ({
  type: SET_ID_LOSS_AGENT_PARENT_DATA,
  payload: payload,
});



export const setBaggageDataPir = (payload:any): IntActionsGeneral => ({
  type: SET_BAGGAGE_DATA,
  payload: payload,
});


export const setFormCapturePir = (payload: any): IntActionsGeneral => ({
  type: SET_FORM_CAPTURE_PIR,
  payload: payload,
});

export const setRetris = (): IntActionsGeneral => ({
  type: SET_RETRIES,
});

export const setIsRefud = (payload:any): IntActionsGeneral => ({
  type: SET_IS_REFUND,
  payload:payload
});


export const clearCapturePir = (): IntActionsGeneral => ({
  type: CLEAR_FORM_CAPTURE_PIR,
});


export const setReason = (payload: any): IntActionsGeneral => ({
  type: SET_REASON,
  payload: payload,
});

export const setCalculateCategory = (payload: any): IntActionsGeneral => ({
  type: SET_CALCULATE_CATEGORY,
  payload: payload,
});
/* ==================================================================
  REDUCERS
================================================================== */
// InitialState
export const userInitialState: IntUserReducer = {
  language: '',
  isVIPClient: false,
  recordNumber:'',
  lossAgentParentId: '0',
  isRefund:null,
  calculateCategory:0,
  reason:'',
  userForm: {
    pir:'',
    fechaIncidencia: new Date(),
    apellido:'',
    tarjetaIberiaPlus:'',
    tarjetaIberia:'',
    numberOfRetris:0
  }
};

// Reducer
export const user = (state = userInitialState, action: IntActionsGeneral): IntUserReducer => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case SET_FORM_CAPTURE_PIR:
      return {
        ...state,
        userForm: {
          pir: action.payload.pir,
          fechaIncidencia: action.payload.fechaIncidencia,
          apellido: action.payload.apellido,
          tarjetaIberiaPlus: action.payload.tarjetaIberiaPlus ? action.payload.tarjetaIberiaPlus : '',
          tarjetaIberia: action.payload.tarjetaIberia ? action.payload.tarjetaIberiaPlus : '',
          numberOfRetris: action.payload.numberOfRetris ? action.payload.numberOfRetris : 0
        }
      };
    case SET_ID_LOSS_AGENT_PARENT_DATA:
      return {
        ...state,
        lossAgentParentId: action.payload.lossAgentParentId,
      };
    case SET_IS_REFUND:
      return {
        ...state,
        isRefund: action.payload.isRefund,
      };
    case SET_CALCULATE_CATEGORY:
      return {
        ...state,
        calculateCategory: action.payload,
      };
    case SET_REASON:
      return {
        ...state,
        reason: action.payload,
      };
    case SET_BAGGAGE_DATA:
      return {
        ...state,
        recordNumber: action.payload.recordNumber,
        isVIPClient: action.payload.isVIPClient ? action.payload.isVIPClient : false,
      }; 
    case SET_FORM_CAPTURE_PIR:
      return {
        ...state,
        userForm: {
          pir: action.payload.pir,
          fechaIncidencia: action.payload.fechaIncidencia,
          apellido: action.payload.apellido,
          tarjetaIberiaPlus: action.payload.tarjetaIberiaPlus ? action.payload.tarjetaIberiaPlus : '',
          tarjetaIberia: action.payload.tarjetaIberia ? action.payload.tarjetaIberiaPlus : '',
          numberOfRetris: action.payload.numberOfRetris ? action.payload.numberOfRetris : 0
        }
      };
      case SET_RETRIES:
        return {
          ...state,
          userForm: {
            ...state.userForm,
            numberOfRetris: state.userForm.numberOfRetris + 1 
          }
        };
      case CLEAR_FORM_CAPTURE_PIR:
        return {
          ...state,
          userForm: {
            pir:'',
            fechaIncidencia: new Date(),
            apellido:'',
            tarjetaIberiaPlus:'',
            tarjetaIberia:'',
            numberOfRetris:0
          }
        };
    default:
      return state;
  }
};
