import { IntBaggageReducer } from '../../schemas/baggage.interface';
import { IntActionsGeneral } from '../../schemas/actions.interface';

/* ==================================================================
  TYPES
================================================================== */
export const SET_COUNTRY_SELECT = 'SET_COUNTRY_SELECT';
export const CLEAR_COUNTRY_SELECT = 'CLEAR_COUNTRY_SELECT';
export const SET_FORM_DELIVERY  = 'SET_FORM_DELIVERY';
export const CLEAR_FORM_DELIVERY  = 'CLEAR_FORM_DELIVERY';
export const SET_SUITCASES  = 'SET_SUITCASES';
export const SET_REDIRECT_PIR = 'SET_REDIRECT_PIR';
export const SET_IS_STORE_SELECTED = 'SET_IS_STORE_SELECTED'

/* ==================================================================
  ACTIONS
================================================================== */
export const setCountrySelect = (payload: string): IntActionsGeneral => ({
  type: SET_COUNTRY_SELECT,
  payload: payload,
});


export const clearCountrySelect = (): IntActionsGeneral => ({
  type: CLEAR_COUNTRY_SELECT,

});

export const setFormDelivery = (payload:any): IntActionsGeneral => ({
  type: SET_FORM_DELIVERY,
  payload:payload
});


export const clearFormDelivery = (): IntActionsGeneral => ({
  type: CLEAR_FORM_DELIVERY,
});



export const setSuitcases = (payload:any): IntActionsGeneral => ({
  type: SET_SUITCASES,
  payload:payload
});


export const setRedirectPir = (payload:any): IntActionsGeneral => ({
  type: SET_REDIRECT_PIR,
  payload:payload
});


export const setIsSotoreSelected = (payload:boolean):IntActionsGeneral => ({
  type: SET_IS_STORE_SELECTED,
  payload
});
/* ==================================================================
  REDUCERS
================================================================== */
// InitialState
export const userInitialState: IntBaggageReducer = {
    countrySelected:'',
    suitcases:[],
    deliveryForm:{
        domicilio:'',
        numero:'',
        piso:'',
        localidad:'',
        provincia:'',
        codigoPostal:'',
        pais:'',
        dni:'',
        telefono:'',
        email:'',
        preferencia:'',
        observaciones:'',
        condiciones:'',
    },
    pir_redirect:{
      isPirRedirect: false,
      city: '',
      homeAddress: '',
      origin: 0,
      phone: '',
      supplier: '',
      wo: ''
    },
    isStoreSelected:false
};

// Reducer
export const baggage = (state = userInitialState, action: IntActionsGeneral): IntBaggageReducer => {
  switch (action.type) {
    case SET_COUNTRY_SELECT:
      return {
        ...state,
        countrySelected: action.payload,
      };
    case CLEAR_COUNTRY_SELECT:
      return {
        ...state,
        countrySelected: '',
      };
    case SET_FORM_DELIVERY:
      return {
        ...state,
        deliveryForm:{
            domicilio: action.payload.domicilio,
            numero: action.payload.numero,
            piso: action.payload.piso,
            localidad: action.payload.localidad,
            provincia: action.payload.provincia,
            codigoPostal: action.payload.codigoPostal,
            pais: action.payload.pais,
            dni: action.payload.dni,
            telefono: action.payload.telefono,
            email: action.payload.email,
            preferencia: action.payload.preferencia,
            observaciones: action.payload.observaciones,
            condiciones: action.payload.condiciones,
        },
      };
      case CLEAR_FORM_DELIVERY:
        return {
          ...state,
          deliveryForm:{
            domicilio:'',
            numero:'',
            piso:'',
            localidad:'',
            provincia:'',
            codigoPostal:'',
            pais:'',
            dni:'',
            telefono:'',
            email:'',
            preferencia:'',
            observaciones:'',
            condiciones:'',
          },
        };
    case SET_SUITCASES:
      return {
        ...state,
        suitcases:action.payload
      };
    case SET_REDIRECT_PIR:
      return{
        ...state,
        pir_redirect:{
          isPirRedirect: true,
          city: action.payload.city,
          homeAddress: action.payload.homeAddress,
          origin: action.payload.origin,
          phone: action.payload.phone,
          supplier: action.payload.supplier,
          wo: action.payload.wo,
        }
      }
      case SET_IS_STORE_SELECTED:
      return {
        ...state,
        isStoreSelected: action.payload
      };
    default:
      return state;
  }
};
