import React, { ReactElement, Suspense, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import { useHistory } from 'react-router-dom';

import es from 'date-fns/locale/es';
import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import pt from 'date-fns/locale/pt';
import en from 'date-fns/locale/en-US';

import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { setUserLanguage } from './user.reducer';
import { setView } from '../../core/masterdata/masterdata.reducer';
import 'react-datepicker/dist/react-datepicker.css';
import { IntStore } from '../../schemas/store.interface';
import { getApiVersion, comprobarPir, formatDate, getCodeCompany } from './user.service';
import { IntUserProps } from '../../schemas/user.interface';
import { getCompany } from '../../core/local-storage/local-storage.service';
import { setFormCapturePir } from './user.reducer';
import EaInput from '../../components/form/ea-input/ea-input';
import EaSelect from '../../components/form/ea-select/ea-select';

import { useForm } from '../../hooks/use-forms';
import { validationsFormIBeria, validationsFormVueling, initialFormIberia, initialFormIVueling, listaTarjetasIberiaPlus, setOldValuesPir } from './user.const';
import { showLoading } from '../../components/ui/loading/loading.reducer';
import { userForm } from '../../schemas/userForm.interface';

const User = (props: IntUserProps): ReactElement => {

  const companyStorage = getCompany();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [datosErroneos, setdatosErroneos] = useState(false);


  let initialForm: userForm;
  let validationForm: any;

  useEffect(()=>{
    switch (props.user.language) {
      case 'es-ES':
        registerLocale('es', es);
        break;
      case 'de-DE':
        registerLocale('de', de);
        break;
      case 'en-US':
        registerLocale('en', en);
        break;
      case 'fr-FR':
        registerLocale('fr', fr);
        break
      case 'it-IT':
        registerLocale('it', it);
        break;
      case 'pt-PT':
        registerLocale('pt', pt);
        break
      default:
        registerLocale('es', es);
        break;
    }
  },[props.user.language])

  switch (companyStorage) {
    case 'iberia':
      initialForm = initialFormIberia;
      validationForm = validationsFormIBeria(companyStorage)
      break;
    case 'vueling':
      initialForm = initialFormIVueling;
      validationForm = validationsFormVueling(companyStorage)
      break;
    default:
      initialForm = initialFormIVueling;
      validationForm = validationsFormVueling('vueling');
      break;
  }

  initialForm = setOldValuesPir(props.user.userForm);
  const retris = props.user.userForm.numberOfRetris + 1;
  /**
   * Control del formulario.
   */
  const {
    values,
    errors,
    bindingFieldName,
    bindingCalendarEvent,
    bindingFieldNameToUpperCase,
    isValidForm,
  } = useForm(validationForm, initialForm);

  /**
   * Extraemos valores del formulario para facilitar el procesamiento
   */
  const { pir, apellido, fechaIncidencia, tarjetaIberiaPlus, tarjetaIberia } = values;

  /**
   * Evento submit formulario preparamos el payload para realizar el post
   */
  const eventContinueBaggage = async (e: any) => {
    e.preventDefault();

    if (!isValidForm()) {
      return;
    }


    const payload = {
      NumberOfRetris: retris,
      Code: pir,
      DateTime: formatDate(fechaIncidencia),
      Name: apellido.toUpperCase(),
      FrequencyFly: tarjetaIberia,
      Firm: getCodeCompany(companyStorage),
    };
    dispatch(setFormCapturePir({ ...values, numberOfRetris: retris }));

    dispatch(showLoading());
    const response  = await props.comprobarPir(payload, history);

    if(response && response.data && response.data.hasOwnProperty('numberOfTries')){
      setdatosErroneos(true)
    }

  };


  return (
    <Suspense fallback="">
      <div className="card">
        <div data-testid="user">
          <h4>{t(`${companyStorage}.captura.Cumplimente`)}</h4>
          <p>{t(`${companyStorage}.captura.lblEntorno`)}</p>
        </div>
        <form onSubmit={(e) => { eventContinueBaggage(e) }}>
          <EaInput
            title={t(`${companyStorage}.captura.lblPirTooltip`)}
            label={t(`${companyStorage}.captura.lblPir`)}
            idInput={'pir-input'}
            type="text"
            bindingField={bindingFieldNameToUpperCase('pir')}
            error={errors}
            inputDataQa="user-pir-input"
            name="pir"            
          ></EaInput>
          <div className="ea-input">
            <label>{t(`${companyStorage}.captura.lblFechaPir`)}</label>
            <DatePicker
              title={t(`${companyStorage}.captura.lblFechaPirTooltip`)}
              id="user-date-picker"
              selected={fechaIncidencia}
              dateFormat={'yyyy/MM/dd'}
              name="fechaIncidencia"
              {...bindingCalendarEvent('fechaIncidencia')}
              locale={props.user.language.slice(0 ,2)}
              data-qa="datepicker_user"
            />
          </div>
          <EaInput
            title={t(`${companyStorage}.captura.lblNombreUsuario`)}
            label={t(`${companyStorage}.captura.lblNombreUsuario`)}
            idInput={'apellido-input'}
            type="text"
            bindingField={bindingFieldNameToUpperCase('apellido')}
            error={errors}
            name="apellido"
            inputDataQa="user-pir-apellido"
          ></EaInput>

          {companyStorage  && (companyStorage === 'iberia') && (
            <>
              <EaSelect
                idInput={"user-tarjeta-iberia-plus"}
                label={t(`${companyStorage}.captura.lblTitleTarjeta`)}
                name="tarjeta-iberia-plus"
                bindingField={bindingFieldName('tarjetaIberiaPlus')}
                listItems={listaTarjetasIberiaPlus}
                error={errors}
                inputDataQa="user-pir-select-tarjeta"

              ></EaSelect>

              {tarjetaIberiaPlus  &&  (tarjetaIberiaPlus != 'N') &&(
                  <EaInput
                    label={t(`${companyStorage}.captura.lblTitleTarjetaInput`)}
                    idInput={"tarjeta-input"}
                    type="text"
                    bindingField={bindingFieldName('tarjetaIberia')}
                    error={errors}
                    name="tarjetaIberia"
                    inputDataQa="user-pir-tarjeta"

                  ></EaInput>
              )}
            </>
          )}

          {datosErroneos &&(
            <p className="error error-text">{t('DEFAULT_ERROR_INTRODUCIR_PIR')}</p>
          )}
          <div className="btn-wrapper">
            <button data-qa="submit-pir" className="btn btn--primary"  id="btn-user-continue" disabled={!isValidForm()}>{t(`${companyStorage}.captura.continue`)}</button>
          </div>

        </form>
      </div>
    </Suspense>
  );
};

const mapStateToProps = (state: IntStore) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = {
  setUserLanguage,
  getApiVersion,
  comprobarPir,
  setView,
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
