import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import styles from './ea-select.module.scss';


const EaSelect = (props: any): ReactElement => {
  const { t } = useTranslation();

  
  
  return (
    <>
      <label>{props.label}</label>
      <select
        id={props.idInput}
        name={props.name}
        {...props.bindingField}
        data-qa={props.inputDataQa}
        value={props.value} 
        disabled={props.disabled!=undefined?props.disabled:false}
      >
        {props.listItems && props.listItems.map((item:any) => (
          <option
            key={item.value}
            value={item.value}
          >
            {t(item.name)}
          </option>
        ))}
      </select>

      {!props.error?.[props.name]?.isValid && <span className="error">{ t(props.error?.[props.name]?.message)}</span>}
       {/* {Segunda validacion para array forms } */}
       {!props.error?.isValid && <span className="error">{ t(props.error?.message)}</span>}
    </>
  );
};

export default EaSelect;
