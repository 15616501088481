import axios from 'axios';
import { hideLoading } from '../../components/ui/loading/loading.reducer';
import { getCompany, getUserLang } from '../../core/local-storage/local-storage.service';
import { environment } from '../../environments/environment';
import { showError } from '../error/error.reducer';
import { setCountrySelect } from './baggabe.reducer';
// import { showError } from '../error/error.reducer';

export const getListCountries = (useHistory) => {

  const companyStorage = getCompany();
  const language = getUserLang();
  const url = environment.endpoints.countries + '' + language.slice(0, 2).toUpperCase();

  return (dispatch: any) => {
    return axios
      .get(url)
      .then((response) => {
        if(!response || !response.data ){
          console.log('GET_LIST_COUNTRIES :NO RESPONDIO CON LA ESTRUCTURA ESPERADA ')
          return;
        }
        
        return response.data;
      })
      .catch((error) => {
        dispatch(showError({ msg: 'ERROR:BAGGAGE_SERVICE getListCountries', redirect: `${companyStorage}/baggage`, codeError: 'ERR000001' }));
        useHistory.push(`/${companyStorage}/error`);
      }).finally(()=>{
        dispatch(hideLoading())
      })
  };
};



export const getRecords = (recordNumber:string) => {
  const companyStorage = getCompany();
  const params = new URLSearchParams({recordNumber});


  return (dispatch: any , history:any) => {
    return axios
      .get(environment.endpoints.records, { params })
      .then((response) => {
        if(!response || !response.data || !response.data.data){
          console.log('GET_RECORDS:NO RESPONDIO CON LA ESTRUCTURA ESPERADA ')
          return;
        }
        return response.data.data;
      })
      .catch((error) => {
        dispatch(setCountrySelect(''));
        dispatch(showError({ msg: 'BAGGAGE_SERVICE getRecords', redirect: `${companyStorage}/baggage`, codeError: 'ERR000001' }));
      }).finally(()=>{
        dispatch(hideLoading())
      })
  };
};





export const getDprs = (payload:any) => {
  const companyStorage = getCompany();
  return (dispatch: any) => {
    return axios
      .post(environment.endpoints.Dprs , payload)
      .then((response) => {
        if(!response || !response.data || !response.data.data){
          console.log('GET_DPRS:NO RESPONDIO CON LA ESTRUCTURA ESPERADA ')
          return;
        }
   
        return response.data.data;
      })
      .catch((error) => {
        dispatch(showError({ msg: 'ERROR: BAGGAGE_SERVICE getDprs', redirect: `${companyStorage}/baggage/selection`, codeError: 'ERR000001' }));
      }).finally(()=>{
        dispatch(hideLoading())
      })
  };
};




