import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { environment } from '../../environments/environment';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: (language: string) => `${environment.static.translate}${language}.json`,
      // loadPath: (language: string) => `/static/locales/${language}.json`,
      allowMultiLoading: false,
      queryStringParams: {},
    },
    detection: {
      htmlTag: document.documentElement,
    },
    supportedLngs: ['es'],
    lng: 'es',
    fallbackLng: 'es',
    ns: [''],
    defaultNS: '',
    debug: false, // environment.debug
    interpolation: {
      escapeValue: false, // react already safes from xss
      formatSeparator: ',',
    },
    react: {
      wait: true,
    },
  });


export default i18n;
