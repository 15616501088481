import i18next from 'i18next';
import { getUserLang } from '../local-storage/local-storage.service';

export const loadLanguage = () => {
  if (getUserLang()) {
    i18next.init({
      supportedLngs: [getUserLang()],
      lng: getUserLang(),
    });
  }
};
