import { connect } from 'react-redux';
import React, { ReactElement } from 'react';
import { IntStore } from '../../schemas/store.interface';
import { IntNotFoundProps } from '../../schemas/not-found.interface';
import { useTranslation } from 'react-i18next';

const NotFound = (props: IntNotFoundProps): ReactElement => {

  const { t } = useTranslation();

  return (
    <div className="not-found" data-testid="not-found">
      {props.error?.notFound && (
        <div>
           {t(`NOT_FOUND`)}
          <div className="not-found--image"></div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: IntStore) => {
  return {
    error: state.error,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
