import { AvailableValidations } from "../../../hooks/use-forms"
import { validateEmail , validateConditions, checkCodePostalIslands} from "../../../utils/validators"
export const  initialFormDelivery = {
    domicilio:'',
    numero:'',
    piso:'',
    localidad:'',
    provincia:'',
    codigoPostal:'',
    pais:'',
    dni:'',
    telefono:'',
    email:'',
    preferencia:'',
    observaciones:'',
    condiciones:'',
}

export const validationsDelivery = {
    domicilio:{
        type: AvailableValidations.REQUIRED,
        value: '',
        message: `DEFAULT_ERROR_REQUIRED`,
    },
    numero:{
        type: AvailableValidations.REQUIRED,
        value: '',
        message: `DEFAULT_ERROR_REQUIRED`,
    },
    piso:{
        type: AvailableValidations.REQUIRED,
        value: '',
        message: `DEFAULT_ERROR_REQUIRED`,
    },
    localidad:{
        type: AvailableValidations.REQUIRED,
        value: '',
        message: `DEFAULT_ERROR_REQUIRED`,
    },
    provincia:{
        type: AvailableValidations.REQUIRED,
        value: '',
        message: `DEFAULT_ERROR_REQUIRED`,
    },
    codigoPostal:{
        type: AvailableValidations.FUNCTION,
        value: checkCodePostalIslands,
        message: `DEFAULT_ERROR_REQUIRED`,
    },
    pais:{
        type:'',
        value: '',
        message: `DEFAULT_ERROR_REQUIRED`,
    },
    dni:{
        type: AvailableValidations.REQUIRED,
        value: '',
        message: `DEFAULT_ERROR_REQUIRED`,
    },
    telefono:{
        type: AvailableValidations.REQUIRED,
        value: '',
        message: `DEFAULT_ERROR_REQUIRED`,
    },
    email:{
        type: AvailableValidations.FUNCTION,
        value: validateEmail,
        message: `DEFAULT_ERROR_EMAIL`,
    },
    preferencia:{
        type: AvailableValidations.REQUIRED,
        value: '',
        message: `DEFAULT_ERROR_REQUIRED`,
    },
    observaciones:{
        type: '',
        value: '',
        message: `DEFAULT_ERROR_REQUIRED`,
    },
    condiciones:{
        type: AvailableValidations.FUNCTION,
        value: validateConditions,
        message: `DEFAULT_ERROR_REQUIRED`,
    },
}