import { IntActionsGeneral } from '../../../schemas/actions.interface';
import { IntLoadingReducer } from '../../../schemas/loading.interface';

/* ==================================================================
  TYPES
================================================================== */
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

/* ==================================================================
  ACTIONS
================================================================== */
export const showLoading = (): IntActionsGeneral => ({
  type: SHOW_LOADING,
});
export const hideLoading = (): IntActionsGeneral => ({
  type: HIDE_LOADING,
});

/* ==================================================================
  REDUCERS
================================================================== */
// InitialState
export const loadingInitialState: IntLoadingReducer = {
  show: false,
};

// Reducer
export const loading = (
  state = loadingInitialState,
  action: IntActionsGeneral
): IntLoadingReducer => {
  switch (action.type) {
    case SHOW_LOADING:
      return {
        ...state,
        show: true,
      };
    case HIDE_LOADING:
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};
